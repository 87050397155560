import React from 'react'
import {Helmet} from "react-helmet"
import { Transition, TransitionGroup } from 'react-transition-group'
import NodeInner from './NodeInner'
import {WEBSITE_NAME, BASE} from '../../config'
import {realPath} from '../../lib/util'

import memoize from '../../lib/memoize'
class Node extends React.PureComponent {
	getData = memoize((location, search, node, first, language, screen, user) => {
		return {location, search, node, first, language, screen, user}
	})
	
	render() {
		const {location, language, first, node, screen, user} = this.props
		//const {location} = this.state
		
		if (!node) return null

		const data = this.getData(location, this.search, node, first, language, screen, user)
		const href = typeof window === 'undefined'
                        ?`${BASE}${global.location}`
                        :window.location.href
		const classes = `node node-${this.first?'first':'notfirst'}`
		const title = WEBSITE_NAME + ((node && node.title)?` - ${node.title}`:'')
		let meta = [
			{name:'og:url', content:href},
			{name:'og:type', content:'article'},
			{name:'og:title', content:title},
			{name:'og:description', content:''},
			{name:'og:locale', content:'ro_RO'},
			{name:'twitter:url', content:href},
			{name:'twitter:card', content:'summary'},
			{name:'twitter:title', content:title},
			{name:'twitter:description', content:''}
		]
		if (node && node.thumb && node.thumb.length>0)
			meta.push ({name: "og:image", content: realPath(node.thumb[0].url, 'thumb')})
		return	(<>
			<Helmet
				title={title}
				meta={meta}/>
			<TransitionGroup className={classes} appear={true} enter={true}>
				<Transition key={node._id.$oid} unmountOnExit={true}
					timeout={1000}>
					{status => <NodeInner status={status} data={data}/>}
				</Transition>
			</TransitionGroup>
		</>)
	}
}

export default Node
/*
			<Helmet>
                <title>{title}</title>
                <meta property="og:url" content={href} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content="" />
                {node && node.thumb && node.thumb.length>0 && <meta property="og:image" content={realPath(node.thumb[0].url, 'thumb')} />}
                <meta property="og:locale" content="ro_RO" />
                <meta property="twitter:url" content={href} />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content="" />
            </Helmet>
*/