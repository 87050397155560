import React from 'react'
import Counter from './Counter'

const CounterRenderer = ({domRef, field, value, fieldInfo, data}) => {
    	if(!value) return <div/>
   
    	const fieldProps = {}
	    if (fieldInfo.id) fieldProps.id = fieldInfo.id
    	const classes = `field-${field} ` + (fieldInfo._class?fieldInfo._class:'')

		return (
			<div ref={domRef} className={classes} {...fieldProps}>
				<Counter label={value.label} value={value.count} data={data}/>
    		</div>
    	)
	
}
export default CounterRenderer