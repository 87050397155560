import Text from './Text'
import HTML from './HTML'
import Img from './Img'
import Date from './Date'
import Icon from './Icon'
import Any from './Any'
import {fieldRenderer} from '../../conf/fieldRenderer'
//const customRenderers = {}
const defaultRenderers = {
	text: Text,
	html: HTML,
	date: Date,
	img: Img,
	icon: Icon,
	any: Any
}
const renderers = Object.assign({}, defaultRenderers, fieldRenderer)
export default renderers
