import {fieldTypes as customFieldTypes} from '../../../conf/field'

const fieldSettings = [
	{name: '_nodel', type: 'bool', label: 'Nu se poate șterge', widget: 'toggle', require: 'admin'},
	{name: '_hidden', type: 'bool', label: 'Ascuns', widget: 'toggle', require: 'admin'},
	{name: 'id', type: 'text', label: 'CSS ID'},
	{name: '_class', type: 'text', label: 'Clasa CSS'},
	{name: '_block', type: 'select', label: 'Container',
		getValues: (entity, field) => entity._info.fields.filter(f => f.isBlock && f.name!==field.name && entity[f.name])
						.map(b => ({val: b.name, label: entity[b.name].label}))
	}
]

let fieldTypes = {
	text: {
		name: 'Text simplu',
		type: 'text',
		getLabel: value => value
	},
	html: {
		name: 'HTML',
		type: 'html',
		getLabel: value => value?(
			(typeof value === 'string')
				?value
				:(value.raw.blocks.length>0?value.raw.blocks[0].text:'')
			):''
		
	},
	date: {
		name: 'Data',
		type: 'date',
		setValue: value => {
			if (value==='now') return {$date: {$numberLong: Date.now()}}
			return value
		}
	},
	img: {
		name: 'Imagine',
		type: 'img',
		getLabel: value => (value && value.length>0)?value[0].name:''
	},
	vid: {
		name: 'Video',
		type: 'vid',
		getLabel: value => value
	},
	video: {
		name: 'Link Video',
		type: 'video',
		getLabel: value => value
	},
	doc: {
		name: 'Doc',
		type: 'doc',
		getLabel: value => value
	},
	bool: {
		name: 'Bool',
		type: 'bool',
		getLabel: value => value
	},
	'class': {
		name: 'Clasă',
		type: 'class',
		getLabel: value => value
	},
	radio: {
		name: 'Butoane radio',
		type: 'radio',
		getLabel: value => ''
	},
	list: {
		name: 'Listă',
		type: 'list',
		getLabel: value => ''
	},
	obj: {
		name: 'Obiect',
		type: 'obj',
		getLabel: value => ''
	},
	custom: {
		name: 'Personalizat',
		type: 'custom',
		getLabel: value => ''
	}
}

Object.keys(customFieldTypes).forEach(type => {
	if (fieldTypes[type]) fieldTypes[type] = Object.assign({}, fieldTypes[type], customFieldTypes[type])
	else fieldTypes[type] = customFieldTypes[type]
})
Object.keys(fieldTypes).forEach(type => {
	fieldTypes[type].settings = [...fieldSettings, ...fieldTypes[type].settings||[]]
})
export {fieldTypes}
