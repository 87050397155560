import React from 'react'
import wrapInfo from '../admin/auth/conf/wrapInfo'
import {query, registerQuery, unregisterQuery, hash} from './api'

class Query extends React.Component {
	constructor(props) {
		super(props)
		
		this.state = {id: 0, value: props.single?null:[], status: 'pending', hash: null}
		this.page = 0
		if (props.q) {
			this.state.hash = hash(props.q)
			this.prepareQuery(props)
			this.data = query(this.q)
			if(!(this.data instanceof Promise)) {
				this.state.value = props.single
					?((this.data.results && this.data.results.length>0)?this.data.results[0]:null)
					:this.data.results
				if (this.state.value && props.setEntityInfo) {
					if (props.single) this.state.value = wrapInfo(this.state.value)
					else this.state.value = this.state.value.map(wrapInfo)
				}
				this.state.total = this.data.total
				this.state.status = 'fulfilled'
				//if (props.onData) props.onData(this.state.value, 'fulfilled')
			} else {
				//if (typeof window === 'undefined') this.data = null
			}
		}
	}
	
	prepareQuery = props => {
		const {pageSize, page, count} = props
		let q = props.q
		this.page = page
		if (pageSize) {
			const skip = this.page * pageSize
			const limit = pageSize
			q = Object.assign({}, q, {
				skip,
				limit,
				count: true
			})
		}
		if (count) {
			q = Object.assign({}, q, {
				count: true
			})
		}
		this.q = q
	}
	
	componentDidMount() {
		if(!this.q) return
		
		if (this.data instanceof Promise) {
			this.data
				.then(data => this.onPromisedResults(this.q, data))
				.catch(error => {console.log(error)})
		} else this.qid = registerQuery(this.q, this.onData)
	}
	
	componentDidUpdate(prevProps) {
		//console.log(prevProps)
		if ((prevProps.q===this.props.q)
			&& prevProps.pageSize===this.props.pageSize
			&& prevProps.page===this.props.page) return
		//console.log('update')
		const {q} = this.props		
		const {id} = this.state
		if (this.props.page!==undefined) this.page = this.props.page
		
		if (!q) {
			this.q = null
			this.setState({
				id: id + 1,
				hash: null,
				value: [],
				total: 0,
				status: 'fulfilled'
			})
			return
		}
		
		const h = hash(q)
		//if (h===this.state.hash) return
		
		if (this.qid) {
			unregisterQuery(this.qid)
			this.qid = null
		}
		this.setState({
			id: id + 1,
			hash: h,
		})
		this.requestData()
	}
	
	requestData = () => {
		this.prepareQuery(this.props)
		this.data = query(this.q)
		if(!(this.data instanceof Promise)) {
			this.qid = registerQuery(this.q, this.onData)
			const value = 
			    this.props.setEntityInfo
			    ?(this.props.single
				?((this.data.results && this.data.results.length>0)?wrapInfo(this.data.results[0]):null)
				:this.data.results.map(wrapInfo)
				)
			    :(this.props.single
				?((this.data.results && this.data.results.length>0)?this.data.results[0]:null)
				:this.data.results
			    )
			this.setState({
				value,
				total: this.data.total,
				status: 'fulfilled'
			})
		} else {
			this.setState({
				status: 'pending'
			})
			this.data
				.then(data => this.onPromisedResults(this.q, data))
				.catch(error => {console.log(error)})
		}
	}
	onPromisedResults = (q, data) => {
		if (this.unmounted) return
		this.qid = registerQuery(q, this.onData)
		this.onData(data)
	}
	onData = data => {
		const {single, setEntityInfo} = this.props
		
		if (this.unmounted) return

		this.data = data
		const value = 
		    setEntityInfo
			?(single
			    ?((data && data.results && data.results.length>0)?wrapInfo(data.results[0]):null)
			    :data?data.results.map(wrapInfo):null)
			:(single
			    ?((data && data.results && data.results.length>0)?data.results[0]:null)
			    :data?data.results:null)
			
		this.setState({
			value,
			total: data?data.total:0,
			status: 'fulfilled'
		})
		//if (this.props.onData) this.props.onData(value, 'fulfilled')
	}
	
	componentWillUnmount() {
		this.unmounted = true
		if (this.qid) unregisterQuery(this.qid)
	}
	
	first = () => {
		if (this.unmounted)	 return
		this.page = 0
		this.requestData()
	}
	prev = () => {
		if (this.unmounted)	 return
		if (this.page>0) this.page = this.page - 1
		this.requestData()
	}
	next = () => {
		const {total} = this.state
		const {pageSize} = this.props
		const pages = Math.ceil(total / pageSize)
		console.log(total)
		if (this.unmounted)	 return
		if (this.page<pages-1) this.page = this.page + 1
		this.requestData()
	}
	last = () => {
		if (this.unmounted)	 return
		const {total} = this.state
		const {pageSize} = this.props
		const pages = Math.ceil(total / pageSize)
		if (this.page<pages-1) this.page = pages - 1
		this.requestData()
	}
	goto = page => {
		if (this.unmounted)	 return
		this.page = page
		this.requestData()
	}
	render() {
		const {value, status, id, total} = this.state
		
		return this.props.children(value, status, id, total, {
			first: this.first,
			next: this.next,
			prev: this.prev,
			last: this.last,
			goto: this.goto,
			page: this.page
		})
	}
}
export default Query
