import React from 'react'
import {realPath} from '../../lib/util'
import Carousel from '../Carousel'
import LazyImage from '../LazyImage'
import WindowSize from '../WindowSize'

class Img extends React.PureComponent {
	componentDidMount() {
		if (this.props.value && this.props.value.length===0 && 
			this.props.fieldInfo.onActive) this.props.fieldInfo.onActive(this.props.value[0], this.props.data)
	}
	onActive = (i, item, data) => {
        if (this.props.fieldInfo.onActive) this.props.fieldInfo.onActive(this.props.value[i], item, data)
    }

	renderCarousel = screen => {
		const {value, fieldInfo, data} = this.props

		const extraProps = {}
        if (fieldInfo.onActive) extraProps.onActive = this.onActive

		return (
			<Carousel {...fieldInfo.widgetProps} data={data} {...extraProps}>
				{value.map((img, i) => {
					if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i, data.language)
				const mime = img.mime?img.mime.split('/'):'image'
				if (mime[0]==='video') 
					return <video autoPlay playsInline muted loop>
                        <source src={realPath(img.url)} type={img.mime}/>
                    </video>
				return <LazyImage key={i} src={img}/>
				})}
			</Carousel>
		)
	}

	render() {
		const {domRef, entity, className, field, value, fieldInfo, data, ...props} = this.props
		
		const imgProps = {}
		if (fieldInfo.imageStyle) imgProps.imageStyle = fieldInfo.imageStyle
		if (fieldInfo.bg) imgProps.bg = fieldInfo.bg
		if (fieldInfo.proportional) imgProps.proportional = fieldInfo.proportional
		
		if (!value || value.length===0) return null
		
		if (fieldInfo.widget === 'carousel') {
			return 	(
						<WindowSize>
							{this.renderCarousel}
						</WindowSize>
					)
		}
	
		/*if (value.length===1) {
			if (fieldInfo.itemRenderer) return <div {...props}>{fieldInfo.itemRenderer(value[0], 0, data.language)}</div>
			const mime = value[0].mime?value[0].mime.split('/'):'image'
			if (mime[0]==='video') {
				return <video ref={domRef} className={className} autoPlay playsInline muted loop {...props}>
	                        <source src={realPath(value[0].url)} type={value[0].mime}/>
	                    </video>
			}
			if (mime[0]==='image') {
				if (fieldInfo.wrap) return <div className={className} ref={domRef}><LazyImage src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/></div>
				return <LazyImage  ref={domRef} className={className} src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/>
			}
			return null
		}*/

		return 	(
			<>
				{value.map((img, i) => {
					if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i, data.language)
					const mime = img.mime?img.mime.split('/'):'image'
					if (mime[0]==='video') 
						return (
							<video ref={domRef} className={className} autoPlay playsInline muted loop>
                        		<source src={realPath(img.url)} type={img.mime}/>
                    		</video>
                    	)
				
					if (fieldInfo.wrap) return <div key={i} className='img'><LazyImage src={img} {...imgProps} {...props}/></div>
					return <LazyImage key={i} src={img} {...imgProps} {...props}/>
				})}
			</>
		)
	}
}
export default Img
