import React from 'react'
import LazyImage from '../ui/LazyImage'
import {TweenMax} from 'gsap' 

const sections = [
	{val: '0', label: 'Evenimente'},
	{val: '1', label: 'Despre muzeu'},
	{val: '2', label: 'Colecția actuală'},
	//{val: '3', label: 'Artiști'},
	//{val: '4', label: 'Simpozioane'},
	{val: '5', label: 'Contact'},
]
const labels = {
	ro: {
		tehnica: 'Tehnica',
		category: 'Categorie',
		an: 'An',
		size: 'Dimensiuni'
	},
	en: {
		tehnica: 'Technique',
		category: 'Category',
		an: 'Year',
		size: 'Size'
	}
}

const move = (t, prev, active, direction) => {
	const prevImg = prev.querySelector('.lazy')
	const activeImg = active.querySelector('.lazy')
					
	if (direction === 'goto') {
		if (prev!==active) {
			
		}
			
		t.add(TweenMax.fromTo(active, 1, 
		{opacity: 0},
		{opacity: 1}), 0)
		t.add(TweenMax.fromTo(activeImg, 0.5, 
		{backgroundColor: 'rgba(60, 39, 0, 1)'},
		{backgroundColor: 'rgba(60, 39, 0, 0)'}), 0.5)
		return
	}
		
	t.add(TweenMax.fromTo(prev, 1, 
	{opacity: 1},
	{opacity: 0}), 0)
	t.add(TweenMax.fromTo(prevImg, 0.2, 
	{backgroundColor: 'rgba(60, 39, 0, 0)'},
	{backgroundColor: 'rgba(60, 39, 0, 1)'}), 0)
					
	t.add(TweenMax.fromTo(active, 1, 
	{opacity: 0},
	{opacity: 1}), 0)
	t.add(TweenMax.fromTo(activeImg, 1, 
	{backgroundColor: 'rgba(60, 39, 0, 1)'},
	{backgroundColor: 'rgba(60, 39, 0, 0)'}), 0.5)
}

const carouselOnActive = (img, item, data) => {
	const language = data.language
	const rect = item.getBoundingClientRect()
	const itemContainer = item.querySelector('.prop-container')
	//console.log(item, rect, itemContainer)
	let w = rect.width
	let h = rect.width * img.h / img.w
	if (h>rect.height) w = rect.height * img.w / img.h 
	if (itemContainer) itemContainer.style.width = `${w}px`
	if (data.onWorkTitle && img.meta) data.onWorkTitle((img.meta._i18n && img.meta._i18n[language])?img.meta._i18n[language].title:img.meta.title)
}
const carouselItemRenderer = (img, i, language) => {
	    		//console.log(img.meta)
	    		return <div key={i} className='item-in'>
	    				<LazyImage bg={true} proportional={true} src={img}/>
	    				{img.meta && <div className='meta'>
	    					{(img.meta._i18n && img.meta._i18n[language])
	    						?<>
	    							{img.meta._i18n[language].tehnica && <><div className='label'>{labels[language]['tehnica']}</div><div className='value'>{img.meta._i18n[language].tehnica}</div></>}
	    							{img.meta._i18n[language].category && <><div className='label'>{labels[language]['category']}</div><div className='value'>{img.meta._i18n[language].category}</div></>}
	    							{img.meta._i18n[language].an && <><div className='label'>{labels[language]['an']}</div><div className='value'>{img.meta._i18n[language].an}</div></>}
	    							{img.meta._i18n[language].size && <><div className='label'>{labels[language]['size']}</div><div className='value'>{img.meta._i18n[language].size}</div></>}
	    						</>
	    						:<>
	    							{img.meta.tehnica && <><div className='label'>{labels[language]['tehnica']}</div><div className='value'>{img.meta.tehnica}</div></>}
	    							{img.meta.category && <><div className='label'>{labels[language]['category']}</div><div className='value'>{img.meta.category}</div></>}
	    							{img.meta.an && <><div className='label'>{labels[language]['an']}</div><div className='value'>{img.meta.an}</div></>}
	    							{img.meta.size && <><div className='label'>{labels[language]['size']}</div><div className='value'>{img.meta.size}</div></>}
	    						</>}
	    				</div>}
	    			</div>
	    	}
const carouselMeta = [
				{name: 'title',	type: 'text', fullWidth: true, label: 'Titlu'},
				{name: 'artist', type: 'reference', label: 'Artist', _t:false,
					ref: entity => entity?{id: entity._id.$oid, title: entity.title, _path: entity.path}:null,
					title: entity => entity?entity.title:'',
					query: inputValue => ({
						query: {bundle: 'artist', title: {$regex: `^${inputValue}.*`, $options: 'im'}},
						projection: {title: 1, path: 1},
						sort: {title: 1}
					})
				},
				{name: 'articles', type: 'list', label: 'Articole', _t: false,
					items: {type: 'reference', label: 'Articol',
						ref: entity => entity?{id: entity._id.$oid, title: entity.title, _path: entity.path}:null,
						title: entity => entity?entity.title:'',
						query: inputValue => ({
							query: {bundle: 'article', title: {$regex: `^${inputValue}.*`, $options: 'im'}},
							projection: {title: 1, path: 1},
							sort: {title: 1}
						})
					}
				},
				{name: 'an',	type: 'text', label: 'An'},
				{name: 'tehnica', type: 'text', fullWidth: true, label: 'Tehnica'},
				{name: 'category',	type: 'text', label: 'Categorie'},
				{name: 'size',	type: 'text', fullWidth: true, label: 'Domensiuni'},
			]	    	
const entityFields = {
	homepage: [
    ],
    article: [
	    {name: 'title', type: 'text', label: 'Titlu', fullWidth: true},
	    {name: 'section', type: 'select', label: 'Secțiune', _t: false, values: sections, display: false},
	    {name: 'date', type: 'date', label: 'Data', _t: false, display: false},
	    {name: 'html', type: 'html', label: 'HTML'},
	    {name: 'img', type: 'img', label: 'Imagini', _t: false,
	    	widget: 'carousel', widgetProps: {responsiveHeight: false, move},
	    	itemRenderer: carouselItemRenderer,
	    	onActive: carouselOnActive,
	    	meta: carouselMeta
	    },
    ],
    artist: [
	    {name: 'title', type: 'text', label: 'Titlu', fullWidth: true},
	    {name: 'first_name', type: 'text', label: 'Prenume', _t: false},
	    {name: 'last_name', type: 'text', label: 'Nume', _t: false},
	    {name: 'birthday', type: 'date', label: 'Data nașterii', _t: false},
	    {name: 'info', type: 'html', label: 'Info'},
	    {name: 'img', type: 'img', label: 'Imagini', _t: false, 
	    	widget: 'carousel', widgetProps: {responsiveHeight: false, move},
	    	itemRenderer: carouselItemRenderer,
	    	onActive: carouselOnActive,
	    	meta: carouselMeta
	    },
	],
    symposium: [
	    {name: 'title', type: 'text', label: 'Titlu', fullWidth: true},
	    {name: 'date', type: 'date', label: 'Data', display: false, _t: false},
	    {name: 'html', type: 'html', label: 'HTML'},
	    {name: 'img', type: 'img', label: 'Imagini', _t: false, 
	    	widget: 'carousel', widgetProps: {responsiveHeight: false, move},
	    	itemRenderer: carouselItemRenderer,
	    	onActive: carouselOnActive,
	    	meta: carouselMeta
	    },
    ],
	page: [
	    {name: 'title', type: 'text', label: 'Titlu', region:'title'},
    ],
	fragment: [
	    {name: 'title', type: 'text', label: 'Titlu', region:'title'},
    ],
}
export {entityFields}
