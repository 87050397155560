import React from 'react'

const renderValue = value => {
	const toks = value.split('®')
	if (toks.length === 1) return value
	return toks.map((text, i) => {
		if (i===0) return text
		return (
			<React.Fragment key={i}>
				<sup>®</sup>{text}
			</React.Fragment>
		)
	})
}

const Text = ({domRef, entity, field, value, fieldInfo, data, ...props}) => {
	if (!value) return null
	return renderValue(value)
}
export default Text
