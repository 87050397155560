import React from 'react'
import LazyImage from '../../ui/LazyImage'
import Carousel from '../ui/Carousel'

const BlockRenderer = props => {
	const {domRef, field, value, id, className, entity, entityInfo, fieldInfo, data, ...other} = props

    if(!value) return <div ref={domRef}/>

	let styleBlock = {}
	let classes = className
	if (value.p &&
		value.bg && value.bg.length>0 &&
		value.bg[0].w>0 && value.bg[0].h>0) {
			let paddingTop = (value.bg[0].h / value.bg[0].w * 100) + '%'
			classes += ' block-proportional'
			
			if (value.bg[0].srcset) {
				value.bg[0].srcset.forEach(srcset => {
					if (srcset.mq.indexOf(data.screen.MQ)>=0 &&
						srcset.h > 0 &&
						srcset.w > 0) {
						paddingTop = (srcset.h / srcset.w * 100) + '%'
					}
				})
			}

			styleBlock = {
				height: 0,
				paddingTop
			}
	}
	if (value.f &&
		value.bg && value.bg.length>0 &&
		value.bg[0].w>0 && value.bg[0].h>0) {
		classes += ' block-fullfixed'
	}
	
	if (value.c) {
		const c = value.c.split(',')
		const sizes = {}
		c.forEach(item => {
			const toks = item.split(':')
			if (toks.length === 2) sizes[toks[0]] = toks[1]
		})
		if (sizes[data.screen.MQ]) {
			
			return (
			<div ref={domRef} id={id} className={classes} {...other}><div className='block-inner' style={styleBlock}>
    			{value.bg && value.bg.length>0 && value.bg.map((bg, i) => <LazyImage key={i} className='bg' bg={true} src={bg}/>)}
    			<div className='block-content'>
    			<Carousel nVisible={sizes[data.screen.MQ]} >
					{props.children}
				</Carousel>
				</div>
    		</div></div>
    		)
		}
	}
	
    return <div ref={domRef} id={id} className={classes} {...other}><div className='block-inner' style={styleBlock}>
    	{value.bg && value.bg.length>0 && value.bg.map((bg, i) => <LazyImage key={i} className='bg' bg={true} src={bg}/>)}
    	<div className='block-content'>
			{props.children}
		</div>
    </div></div>
}
export default BlockRenderer

