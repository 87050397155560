import React from 'react'
import renderers from './renderers'
import {LANGUAGES} from '../config'
import {intersectionObserve, intersectionUnobserve} from '../lib/intersection'
import {publish} from '../lib/pubsub'
import memoize from '../lib/memoize'
import {fieldTypes} from '../admin/auth/conf/field'

class Field extends React.PureComponent {
	constructor(props) {
		super(props)
		this.domRef = React.createRef()
		this.state = {visible: false}
	}
	componentDidMount() {
		if (!this.props.fieldInfo || this.props.fieldInfo.display === false) return
		this.setupIntersectionObserve()
	}
	setupIntersectionObserve = () => {
		if (!this.domRef.current) {
			if (!this.iDelay) this.iDelay = 500
			else this.iDelay *= 2
			if (this.iDelay>2000) {
				if (this.unmounted) return
				this.setState({visible: true})
				return
			}
			setTimeout(() => {
				if (this.unmounted) return
				this.setupIntersectionObserve()
			}, this.iDelay)
			return
		}
		intersectionObserve(this.domRef.current, this.onIntersect)
	}
	componentWillUnmount() {
		this.unmounted = true
		if (!this.domRef.current) return
		intersectionUnobserve(this.domRef.current)
	}
	onIntersect = entry => {
		if (this.unmounted || this.state.visible || !entry.isIntersecting ) return
		//console.log('intersect field', this.props.field)
		this.setState({visible: true})
	}
	onMouseOver = e => {
		const {noadmin, data} = this.props
		if (noadmin || !data.user) return
		if (this.mouseOver) return
		this.mouseOver = true
		//console.log('real enter', this.props.field, e)
		//publish('fieldAdmin', {mouse: 'enter', domRef: this.domRef, ...this.props})
		publish('fieldAdmin', {mouse: 'enter', fieldLine: this.parents})
	}
	onMouseLeave = e => {
		const {noadmin, data} = this.props
		if (noadmin || !data.user) return
		this.mouseOver = false
		//console.log('real leave', this.props.field, e)
		//publish('fieldAdmin', {mouse: 'leave', domRef: this.domRef, ...this.props})
		publish('fieldAdmin', {mouse: 'leave', fieldLine: this.parents})
	}
	
	setupLineage = () => {
		/*if (this.parents) {
			const {entity, field} = this.props
			let lineageId = ''
			if (this.props.parents)
				this.props.parents.forEach(item => {
					const {entity, field} = item
					lineageId += `${entity?entity._id.$oid:0} - ${field}`
				})
			lineageId += `${entity?entity._id.$oid:0} - ${field}`
			if (this.lineageId === lineageId) return
		}*/
		this.parents = [...this.props.parents||[], {domRef: this.domRef, ...this.props}]
		this.lineageId = ''
		this.parents.forEach(item => {
			const {entity, field} = item
			this.lineageId += `${entity?entity._id.$oid:0} - ${field}`
		})
	}

	setupData = memoize((fieldInfo, data, visible) => {
		return (fieldInfo && fieldInfo.visibility)
			?{...data, visible}
			:data
	})

	render() {
		const {entity, field, fieldInfo, value, noadmin, ...other} = this.props
		//const {field, id, className, entity, fieldInfo, ...other} = props
		const {visible} = this.state
		//console.log('render field ', field, fieldInfo)
		const data = this.setupData(fieldInfo, this.props.data, visible)

		const fieldInfoLocal = fieldInfo || entity.fieldInfo(field)
		if (fieldInfoLocal && fieldInfoLocal._hidden && !this.props.data.user) return null
		if (!fieldInfoLocal || fieldInfoLocal.display===false) return null

		let val = value || (entity?entity[field]:null)
		if (LANGUAGES) {
			const lang = data.language
			const defaultLanguage = entity._lang || LANGUAGES[0]
			if (lang!==defaultLanguage &&
				fieldInfoLocal._t ===undefined &&
				entity &&
				entity._i18n && 
				entity._i18n[lang] && 
				entity._i18n[lang][field]!==undefined) 
				val = entity._i18n[lang][field]
		}
		
		const Renderer = fieldInfoLocal.renderer
			?renderers[fieldInfoLocal.renderer]
			:(renderers[fieldInfoLocal.type] || 
				renderers[fieldInfoLocal.name] || 
				renderers[fieldTypes[fieldInfoLocal.type].type] ||
				renderers['any'])
		if (!Renderer) return null
		
		let childFields = null
		if (fieldInfoLocal.isBlock) {
			const allFields = entity._info.fields.filter(f => f._block===field).map(f => f.name)
			childFields = (val && val._o)?val._o.split(','):[]
			childFields = childFields.filter(f => allFields.indexOf(f)>=0)
			childFields = childFields.concat(allFields.filter(f => childFields.indexOf(f)<0))
		}
	    const wrapperProps = {}
	    if (fieldInfoLocal.id) wrapperProps.id = fieldInfoLocal.id
    	let classes = `field-${field} field-${fieldInfoLocal.type} ` + (fieldInfoLocal._class?fieldInfoLocal._class:'')
		if (visible) classes += ' field-visible'
		wrapperProps.className = classes
		
		if(data && data.user) {
			this.setupLineage()
			//console.log(this.lineageId, this.parents)
			wrapperProps.onMouseOver = this.onMouseOver
			wrapperProps.onMouseLeave = this.onMouseLeave
		}

		if (fieldInfoLocal._nowrap)
			return (
				<Renderer domRef={this.domRef} 
					entity={entity} field={field} fieldInfo={fieldInfoLocal} value={val} data={data}
					{...wrapperProps} {...other}>
					{childFields && childFields.map((f,i) => 
		      			<Field key={i} field={f} entity={entity} 
	    	  				fieldInfo={entity.fieldInfo(f)} 
	       					data={data} parents={this.parents}/>
	       			)}
	       		</Renderer>
			)
	
		const Tag = fieldInfoLocal.tag || 'div'
		
		return (
			<Tag ref={this.domRef} {...wrapperProps} >
				<Renderer entity={entity} field={field} fieldInfo={fieldInfoLocal} value={val} data={data} {...other}>
					{childFields && childFields.map((f,i) => 
		      			<Field key={i} field={f} entity={entity} 
	    	  				fieldInfo={entity.fieldInfo(f)} 
	       					data={data} parents={this.parents}/>
	       			)}
	       		</Renderer>
	    	</Tag>
	    )
	}
}
export default Field
