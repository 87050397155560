import auth from '../authorize'

let permissions = {}
Object.keys(auth).forEach(perm => {
	if(perm==='entity') return
	permissions[perm] = {roles: auth[perm]}
})

class User {
	constructor(profile) {
		this.profile = profile
		if (profile) {
			this.name = profile['https://linuxship.ro/authorization/realName'] || profile.name
			this.roles = profile['https://linuxship.ro/authorization/roles']
			this.isAdmin = this.roles.indexOf('admin')>=0
        	this.isEditor = this.roles.indexOf('admin')>=0 ||
        					this.roles.indexOf('editor')>=0
        				
		}
	}
	
	can = (perm, data=null) => {
		if(this.isAdmin || this.isEditor) return true
		//console.log(permissions)
		if(!permissions[perm]) return false
		const pdata = permissions[perm]
		if (pdata.cb) return pdata.cb(this, perm, data)

		if (this.profile && pdata.roles) return this.profile.roles.reduce((acc, role) => pdata.roles.includes(role)||acc, false)
	
		return false
	}
	
	hasOneOf = (roles) => {
		for(let i=0;i<roles.length;i++) {
			if (this.roles.indexOf(roles[i])>=0) return true
		}
		return false
	}
	
	is = role => this.roles.indexOf(role)>=0
}
export default User
