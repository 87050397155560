//import bugsnag from 'bugsnag-js'
//import bugsnagReact from 'bugsnag-react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import React from 'react'
import ReactDOM from 'react-dom'
import LanguageManager from './lib/LanguageManager'
import Auth from './admin/Auth'
import NodeManager from './components/NodeManager'

const bugsnagClient = bugsnag('802bcc36517feaa716a5e3b4eba39a00')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')
//const ErrorBoundary = window.bugsnagClient.use(bugsnagReact(React))
if (window.initialStore) window.isHydrating = true
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.hydrate(
	<ErrorBoundary>
			<LanguageManager>
			<Auth>
  				<NodeManager/>
  			</Auth>
  			</LanguageManager>
    </ErrorBoundary>,
  	document.getElementById('root'),
  	() => {
  		window.isHydrating = false
  		delete window.initialStore
  	}
);
//registerServiceWorker();
//unregister()

