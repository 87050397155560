import React, { PureComponent } from 'react';
import Link from '../../ui/Link'
import icons from '../../conf/icons'
import {TimelineMax, TweenMax, Linear} from 'gsap'
import {screen} from '../../lib/screen'

const lines = [
	{id: '#logo_x5F_mask_x5F_M', first: 9.51, w: 61.58},
	{id: '#logo_x5F_mask_x5F_A', first: 7.94, w: 25.35},
	{id: '#logo_x5F_mask_x5F_C', first: 6.62, w: 66.87},
	{id: '#logo_x5F_mask_x5F_S', first: 6.71, w: 53.22},
	{id: '#logo_x5F_mask_x5F_B', first: 7.13, w: 17.69},
]

class Logo extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
  			mode: 0,
		}
	}
	componentDidMount() {
		setTimeout(() => {
			if (this.unmounted) return
			this.animateIn()
		}, 3000)
	}
	componentWillUnmount() {
		this.unmounted = true
    }

	animateIn = () => {
		const t = new TimelineMax({
			onComplete: () => {
				document.getElementById('root').classList.add('stage-loaded')
			}
		})
		t.pause()
		let delta = 0		
		
		// 175x100
		t.set('#logo', {x: screen.W/4, y: screen.H/2 - screen.W/2 * 100/175/2, width: screen.W/2, opacity: 1})
		
		t.add(TweenMax.to('#skip-intro', 1, {opacity: 1}))
		
		// 1st move
		const maskOrigin = '72.5px 72.5px'
		const d = 245 / 2
		let deltai = 2
		t.add(TweenMax.fromTo('#logo_x5F_mask_x5F_1', deltai, 
		{
			x: 0, y: 0, rotation: 45, transformOrigin: maskOrigin
		},{
			x: d, y: d, rotation: 45, transformOrigin: maskOrigin, ease:Linear.easeNone
		}), delta)
		delta += deltai

		let k = delta - deltai * 0.5
		lines.forEach((line, i) => {
			const t1 = line.w * deltai / 100
			t.add(TweenMax.fromTo(line.id, t1, 
			{
				x: -75
			},{
				x: -75 + 6 + line.w, ease:Linear.easeNone
			}), k)
			k += t1
		})
		//delta += deltai

		// 2nd move
		const maskOrigin2 = '55px 55px'
		const d2 = -105
		t.add(TweenMax.fromTo('#logo_x5F_mark_x5F_2', deltai, 
		{
			x: 0, y: 0, rotation: 45, transformOrigin: maskOrigin2
		},{
			x: d2, y: d2, rotation: 45, transformOrigin: maskOrigin2, ease:Linear.easeNone
		}), delta-deltai*0.5)
		delta += deltai-deltai*0.3


		// 3rd move
		t.add(TweenMax.fromTo('#logo_x5F_bg_x5F_blue', deltai, 
		{
			y: 0
		},{
			y: -100, ease:Linear.easeNone
		}), delta-deltai*0.3)
		delta += deltai
		t.add(TweenMax.to('#logo_x5F_bg_x5F_blue', deltai, 
		{
			y: -200, ease:Linear.easeNone
		}), delta+.5)
		delta += deltai
		
		k = delta - deltai * 0.5
		lines.forEach((line, i) => {
			const t1 = line.w * deltai / 100
			t.add(TweenMax.to(line.id, t1, 
			{
				x: -75 + 5.45 + line.first, ease:Linear.easeNone
			}), k)
			k += t1
		})
		delta += deltai*2
		
		// move logo in corner
		t.add(TweenMax.to('#logo', 2,
			{x: 10, y: 10, width: 100}), delta)
			
		t.play()
		this.t = t
	}

	onSkip = e => {
		this.t.progress(1, false)
	}

	render() {
		const {language} = this.props
		const {MacsbIcon} = icons
		return (
			<>
				<div id='logo'><Link to={`/${language}`}><MacsbIcon/></Link></div>
				<div id='skip-intro' onClick={this.onSkip}>Skip intro</div>
			</>
    	)
  	}
}

export default Logo
