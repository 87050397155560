import React from 'react'
import {subscribe, unsubscribe, get} from '../lib/pubsub'

class PubSub extends React.Component {
	constructor(props) {
		super(props)
		this.topics = props.topics.split(',').map(topic => topic.trim())
		const state = {}
		this.topics.forEach(topic => state[topic] = get(topic))
		this.state = state
	}
	componentDidMount() {
		const state = {}
		this.handlers = {}
		this.topics.forEach(topic => {
			const value = get(topic)
			if (this.state[topic]!==value)
				state[topic] = value
			this.handlers[topic] = subscribe(topic, this.onChange)
		})
		this.setState(state)
	}

	componentWillUnmount() {
        this.unmounted = true
		this.topics.forEach(topic => unsubscribe(topic, this.handlers[topic]))
    }
    
    onChange = (topic, value) => {
    	if (this.unmounted) return
    	//console.log('set state', topic, value)
    	this.setState({[topic]: value})
    }

	render() {
		//console.log(this.state)
		return this.props.children(this.state)
	}
}
export default PubSub