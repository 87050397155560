import throttle from 'lodash.throttle'
import fastdom from 'fastdom'
import {publish} from './pubsub'

let screenClasses = null
let aspect = 0

const mq = (W) => {
	if (W <= 400) return 'xss'
	if (W <= 600) return 'xs'
	if (W <= 900) return 'sm'
	if (W <= 1200) return 'md'
	if (W <= 1600) return 'lg'
	return 'xl'
}

const handleResize = () => {
	const W = window.innerWidth
	const H = window.innerHeight
	screen = {}
	if (screen.W === W && screen.H === H) return
	screen.W = W
	screen.H = H
	screen.MQ = mq(screen.W)
	screen.O = screen.W > screen.H ? 'landscape' : 'portrait'
	screen.isPortrait = screen.O==='portrait'
	screen.isMobile = screen.W < 400 || screen.H < 400
	screen.MOBILE = screen.isMobile?'mobile':''
	screen.ASPECT = screen.W / screen.H
	publish('resize', screen)

	const newScreenClasses = `${screen.MQ} ${screen.O} ${screen.MOBILE}`
	if (aspect !== screen.ASPECT) {
		aspect = screen.ASPECT
		//document.documentElement.style.setProperty('--aspect', screen.ASPECT)
	}
	if (screenClasses === newScreenClasses) return
	screenClasses = newScreenClasses
	fastdom.mutate(() => {
		document.body.className = screenClasses
	})
}
const onResize = throttle(() => {
	//fastdom.measure(() => {
		handleResize()
	//})
}, 50)

let screen = (() => {
	if (typeof window === 'undefined') return {
		W: 0,
		H: 0,
		MQ: '',
		O: ''
	}
	window.addEventListener('resize', onResize)
	const W = window.innerWidth
	const H = window.innerHeight
	
	const O = W > H ? 'landscape' : 'portrait'
	const isMobile = W < 400 || H < 400
	const ASPECT = W / H
	aspect = ASPECT
	const MQ = mq(W)
	const MOBILE = isMobile?'mobile':''
	screenClasses = `${MQ} ${O} ${MOBILE}`
	//document.documentElement.style.setProperty('--aspect', ASPECT)
	document.body.className = screenClasses
	setTimeout(() => {handleResize()}, 100)
	return {
		W, H,
		MQ,
		O,
		isPortrait: O==='portrait',
		isMobile,
		MOBILE,
		ASPECT
	}
})()


export {screen}
