import React from 'react'
import Query from '../../lib/Query'
import SymposiumNode from '../symposium/SymposiumNode'

const SymposiumSection = React.memo(({data}) => {
	const q = {
		query: {bundle: 'symposium',
			$or: [
				{_lang: data.language},
				{[`_i18n.${data.language}`]: {$exists: true}}
			]
		},
		sort: {date: -1},
		limit: 1
	}
	
	return (
		<Query q={q} single setEntityInfo>
			{(node, status) => {
				if (status!=='fulfilled') return <div>...</div>
				if (!node) return null
				return <SymposiumNode data={{...data, node}}/>
			}}
		</Query>
	)
})

export default SymposiumSection
