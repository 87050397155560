import React, {lazy, Suspense} from 'react'
import Field from './Field'
const FieldManager = lazy(() => import('../admin/auth/field/FieldManager'));

const Region = React.memo(({entity, region, data, nowrap}) => {
	const currentRegion = region || 'content'
	//console.log('render region', currentRegion, 'for', entity.type)
	let o
	if (entity._o && entity._o[currentRegion]) {
		o = entity._o[currentRegion].split(',').filter(f => entity._info.fields.filter(f1 => f1.name===f).length>0)
	} else {
		o = entity._info.fields.map(f => f.name)
		o = [...entity._info.fields]
		if (currentRegion==='content') o = o.filter(f => f.region===undefined || f.region==='content')
		else  o = o.filter(f => f.region===currentRegion)
		o = o.map(f=>f.name)
	}
	
	o = o.filter(f => !entity._info.fields.filter(f1 => f1.name===f)[0]._block)

	if (nowrap) return <>
			{o.map((f,i) => <Field key={i} field={f} entity={entity} 
				fieldInfo={entity.fieldInfo(f)} data={data}/>)}
			{data.user && <Suspense fallback={<div>...</div>}>
				<FieldManager user={data.user} entity={entity} region={currentRegion}/>
			</Suspense>}
	</>

	return <div className={`region-${currentRegion}`}><div className='region-in'>
			{o.map((f,i) => <Field key={i} field={f} entity={entity} 
				fieldInfo={entity.fieldInfo(f)} data={data}/>)}
			</div>
            {data.user && <Suspense fallback={<div>...</div>}>
            	<FieldManager user={data.user} entity={entity} region={currentRegion}/>
            </Suspense>}
		</div>
})
export default Region
