import {faPlay} from '@fortawesome/free-solid-svg-icons/faPlay'
import {faPause} from '@fortawesome/free-solid-svg-icons/faPause'
import {faMusic} from '@fortawesome/free-solid-svg-icons/faMusic'
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight'
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown'
import {faCaretUp} from '@fortawesome/free-solid-svg-icons/faCaretUp'
import {faPalette} from '@fortawesome/free-solid-svg-icons/faPalette'
import {faFeather} from '@fortawesome/free-solid-svg-icons/faFeather'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle'

const icons = {
	faSpinner,
	faPlay,
	faPause,
	faMusic,
	faAngleRight,
	faAngleLeft,
	faCaretDown,
	faCaretUp,
	faPalette,
	faFeather,
	faInfoCircle
}
export default icons
