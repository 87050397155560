import React, {lazy, Suspense} from 'react'
import AuthContext from '../admin/AuthContext'
import LanguageContext from '../lib/LanguageContext'
//import {addLocationListener} from '../../lib/history'
import Bg from './layout/Bg'
import BgHeader from './layout/BgHeader'
import Header from './layout/Header'
import Node from './layout/Node'
import Footer from './layout/Footer'
import IndexArticles from './article/IndexArticles'
import IndexArtists from './artist/IndexArtists'
import IndexSymposium from './symposium/IndexSymposium'
const HeaderAdminMenu = lazy(() => import('../admin/auth/HeaderAdminMenu'))
const FieldAdmin = lazy(() => import('../admin/auth/field/FieldAdmin'))

const indexes = {
	articles: IndexArticles,
	artists: IndexArtists,
	symposium: IndexSymposium
}
class Layout extends React.PureComponent {
	getIndex = node => {
		if (!node) return null
		
		if (this.nodeId === node._id.$oid) return this.index
		
		let display = 'article'
		this.index = 'articles'
		
		// colectia actuala
		if (node.bundle === 'section' && node.section==='2') {
			display = 'gallery'
			this.index = null
		}
		// artisti
		if (node.bundle === 'section' && node.section==='3') {
			display = 'artists'
			this.index = 'artists'
		}
		// artist
		if (node.bundle === 'artist') {
			display = 'gallery'
			this.index = null
		}
		// symposium section
		if (node.bundle === 'section' && node.section==='4') {
			display = 'gallery'
			this.index = 'symposium'
		}
		// symposium
		if (node.bundle === 'symposium') {
			display = 'gallery'
			this.index = 'symposium'
		}
		// contact section
		if (node.bundle === 'section' && node.section==='5') {
			this.index = null
		}
		
		if (!node || typeof window === 'undefined') {
			return this.index
		}
		
		const root = document.getElementById('root')
		if (display!==this.display) {
			if (this.display) root.classList.remove(`display-${this.display}`)
			this.display = display
			root.classList.add(`display-${display}`)
		}

		const isHome = node.bundle === 'homepage'
		if (this.isHome !== isHome) {
			this.isHome = isHome
		
			if (isHome) {
				root.classList.remove('not-home')
				root.classList.add('is-home')
			} else {
				root.classList.remove('is-home')
				root.classList.add('not-home')
			}
		}
		//this.setState({node, index})
		return this.index
	}



	render() {
		const {node, location, first} = this.props
		const index = this.getIndex(node)
		
		const Index = indexes[index]
		return (
			<AuthContext.Consumer>
        	{({user, logout}) => 
        		<>
			      	{user && 
    	        		<Suspense fallback={<div>...</div>}>
    	        			<HeaderAdminMenu user={user} logout={logout} node={node}/>
    	        		</Suspense>}
	  				<Bg/>
	  				<BgHeader/>
	  				<LanguageContext.Consumer>
	  				{({language}) => 
	  					<>
						<Header language={language}/>
						{Index && <Index language={language} node={node}/>}
						<Node 
							user={user}
							node={node} location={location} first={first}
							language={language} />
						<Footer node={node} language={language}/>
						</>
	  				}
	  				</LanguageContext.Consumer>
  					<div id='modal-container'/>
  					{user && user.is('editor') &&
                    <Suspense fallback={<div>...</div>}>
                        <div id='field-admin-container'><FieldAdmin user={user}/></div>
                    </Suspense>}
    			</>
    	    }
    	    </AuthContext.Consumer>
		)
	}
}
export default Layout
