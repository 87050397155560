import React from 'react'
//import {LANGUAGES} from '../config'
import {addLocationListener} from '../lib/history'
import wrapInfo from '../admin/auth/conf/wrapInfo'
import getNode from '../lib/node'
import {registerQuery, unregisterQuery} from '../lib/api'
import Layout from './Layout'

const nodeNotFound = {
	_id: {$oid:-1},
	bundle: 'NotFound',
	type: 'node'
}

class NodeManager extends React.PureComponent {
	constructor(props) {
		super(props)
		
		const location = typeof window === 'undefined'
			?global.location
			:window.location.pathname
		this.first = true
		this.requestNode(location, false) //false

		this.state = {
			location,
			node: null
		}
		if(!(this.data instanceof Promise)) {
			const node = (this.data && this.data.results && this.data.results.length>0)?wrapInfo(this.data.results[0]):wrapInfo(nodeNotFound)
			//props.onNodeChange(node)
			this.state.node = node
		}
		
	}

	componentDidMount() {
		addLocationListener(this.handleLocationChange)
		if (this.data instanceof Promise) this.waitForPromise()
		if (this.query) this.qid = registerQuery(this.query, this.onResults)
	}

	componentWillUnmount() {
		this.unmounted = true
		if (this.qid) unregisterQuery(this.qid)
	}

	requestNode = (location, register=true) => {
		if (this.qid) unregisterQuery(this.qid)
		const {query, data} = getNode(location, register?this.onResults:null)
		if (!register) this.query = query
		this.data = data
	}
	
	onResults = data => {
		if (this.unmounted) return
		this.data = data
		if (!this.data || !this.data.results || this.data.results.length===0) {
			/*this.setState({
				location: this.location
			})*/
			//this.props.onNodeChange(wrapInfo(nodeNotFound))
			this.setState({node: wrapInfo(nodeNotFound)})
			return
		}
		const node = wrapInfo(this.data.results[0])
		
		/*this.setState({
			location: this.location
		})*/
		//this.props.onNodeChange(node)
		this.setState({node})
	}
	
	waitForPromise = () => {
		if(!(this.data instanceof Promise)) return this.onResults(this.data)
		this.data.then(data => this.onResults(data))
	}

	handleLocationChange = (location, search) => {
		if (this.unmounted) return
		//this.location = location
		this.search = search
		
		//if (LANGUAGES) this.updateLanguage(location)
		this.first = false
		this.requestNode(location)
		this.waitForPromise()
		this.setState({location})
	}
	
	render() {
		const {location, node} = this.state
		
		return (
			<Layout location={location} node={node} first={this.first}/>
		)
	}
}
export default NodeManager
