import {
	entityFields as customEntityFields
} from '../../../conf/entity'

const entityFields = {
}

Object.keys(customEntityFields).forEach(entityType => {
	if (entityFields[entityType]) {
		customEntityFields[entityType].forEach(field => {
			let override = false
			entityFields[entityType] = entityFields[entityType].map(f => {
				if(f.name === field.name) {
					override = true
					return field
				}
				return f
			})
			if(!override) entityFields[entityType].push(field)
		})
	} else {
		entityFields[entityType] = customEntityFields[entityType]
	}
})

export {entityFields}
