import React from 'react'
import FaIcon from '../../ui/FaIcon'
import Auth from '../lib/Auth'
import Link from '../../ui/Link'

const strings = {
	en: 'ENGLISH VERSION',
	ro: 'VERSIUNEA ÎN ROMÂNĂ'
}

class Footer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			play: false
		}
		this.audioRef = React.createRef()
	}
	
	componentWillUnmount() {
		this.unmounted = true
	}

	toggleMusic = e => {
		const play = !this.state.play
		
		this.setState({play})
		if (play) this.audioRef.current.play()
		else  this.audioRef.current.pause()
	}
	play = e => {
		this.setState({play: true})
		this.audioRef.current.play()
	}
	pause = e => {
		this.setState({play: false})
		this.audioRef.current.pause()
	}

	render() {
		const {play} = this.state
		const language = this.props.language==='en'?'ro':'en'
		const {node} = this.props
		let path = `/${language}`
		if (node && node.path) {
			const paths = node.path.filter(item => item.lang===language)
			if (paths.length>0) path = paths[0].p
		}
		
		return (
			<footer>
				<Auth/>
				<div className='audio'>
					<audio loop ref={this.audioRef}>
						<source src="/mp3/Mio_Ritz_Hotel.mp3" type="audio/mp3"/>					
						<source src="/mp3/Waiting_On_The_Hill.mp3" type="audio/mp3"/>
					</audio>
					<div className='controls'>
					<div className={`button music ${play?'play':'pause'}`} onClick={this.toggleMusic}><FaIcon icon='faMusic'/></div>
					</div>
				</div>
				{path && <Link className='language' to={path}>{strings[language]}</Link>}
			</footer>
		)
	}
} 
export default Footer