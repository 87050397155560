import React from 'react'
import FaIcon from './FaIcon'
//import fastdom from 'fastdom'
//import {screen} from '../../lib/screen'
import Hammer from 'hammerjs'
import {TimelineMax, TweenMax} from 'gsap' 

class Carousel extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			classes: 'carousel' + (props.move?'':' default-move'),
			prev: 0,
			active: 0,
			direction: 'goto',
		}
		this.ref = React.createRef()
		this.itemRefs = []
	}
	
	componentDidMount() {
		this.hammer = new Hammer(this.ref.current)
   	    this.hammer.on('swiperight', this.prev)
       	this.hammer.on('swipeleft', this.next)
		if (this.props.onActive) this.props.onActive(this.state.active, this.itemRefs[this.state.active].current, this.props.data)
		this.move()       	
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.prev!==this.state.prev || 
			prevState.active!==this.state.active || 
			prevState.direction!==this.state.direction) {
				this.move()
				return
			}
		this.setPositions()
	}
	componentWillUnmount() {
		this.unmounted = true
	}
	autoSize = () => {
		const {active} = this.state
		if (!this.props.images) {
			const h = this.itemRefs[active].current.clientHeight
			if (!this.width)
				this.width = this.itemRefs[active].current.clientWidth
			this.ref.current.style.height = `${h}px`
			return
		}
		
		const img = this.props.images[active]
		if (!img) return
		
		if (this.props.fillHeight) {
			const h = this.ref.current.clientHeight
			this.height = h
			const w = h * img.w / img.h
			this.width = w
			
			this.ref.current.style.width = `${w}px`
			return
		}
		
		const w = this.ref.current.clientWidth
		this.width = w
		const h = w * img.h / img.w
		this.height = h
		
		this.ref.current.style.height = `${h}px`
	}
	
	prev = e => {
		const n = this.props.children.length
		const nVisible = this.props.nVisible || 1
		if (n<=nVisible) return
		
		const prev = this.state.active
		let active = (this.state.active - 1 + n) % n
		
		this.setState({prev, active, direction: 'prev'})
		if (this.props.onActive) this.props.onActive(active, this.itemRefs[active].current, this.props.data)
	}

	next = e => {
		const n = this.props.children.length
		const nVisible = this.props.nVisible || 1
		if (n<=nVisible) return
		
		const prev = this.state.active
		let active = (this.state.active + 1) %n
		
		this.setState({
			prev,
			active,
			direction: 'next'
		})
		
		if (this.props.onActive) this.props.onActive(active, this.itemRefs[active].current, this.props.data)
	}
	
	setPositions = () => {
		const {active} = this.state
		const n = this.props.children.length
		const nVisible = this.props.nVisible || 1
		if (this.t) this.t.progress(1, false)
		if (this.unmounted) return
		
		if (this.props.autoSize) this.autoSize()

		this.t = new TimelineMax()
		this.t.pause()
		if (this.props.setPositions) this.props.setPositions(this.t, this.itemRefs, active)
		else {
			let endPos = 0
			for(let i=0; i<nVisible; i++) {
				const k = (active + i ) % n
				if (this.itemRefs[k]) {
					const itemWidth = this.itemRefs[k].current.clientWidth
					this.t.set(this.itemRefs[k].current, 
					{x: endPos})
					endPos += itemWidth  
				}
			}
		}
		this.t.play()
	}
	
	move = () => {
		const {prev, active, direction} = this.state
		const n = this.props.children.length
		const nVisible = this.props.nVisible || 1
		if (this.t) this.t.progress(1, false)
		
		if (this.props.autoSize) this.autoSize()
		
		this.t = new TimelineMax()
		this.t.pause()
		
		if(this.props.move) this.props.move(this.t, this.itemRefs, prev, active, direction)
		else {
		if (direction === 'goto') {
			if (prev!==active) {
				
			}
			
			let endPos = 0
			for(let i=0; i<nVisible; i++) {
				const k = (active + i ) % n
				if (this.itemRefs[k]) {
					const itemWidth = this.itemRefs[k].current.clientWidth
					this.t.add(TweenMax.fromTo(this.itemRefs[k].current, 1, 
					{x: endPos + itemWidth},
					{x: endPos}), 0)
					endPos += itemWidth
				}
			}
		}
		
		if (direction === 'next') {
			const prevItemWidth = this.itemRefs[prev].current.clientWidth
			this.t.add(TweenMax.fromTo(this.itemRefs[prev].current, 1, 
			{x: 0},
			{x: -prevItemWidth}), 0)
		
			let endPos = 0
			for(let i=0; i<nVisible; i++) {
				const k = (active + i ) % n
				if (this.itemRefs[k]) {
					const itemWidth = this.itemRefs[k].current.clientWidth
					this.t.add(TweenMax.fromTo(this.itemRefs[k].current, 1, 
					{x: endPos + itemWidth},
					{x: endPos}), 0)
					endPos += itemWidth
				}
			}
			/*this.t.add(TweenMax.fromTo(this.itemRefs[active].current, 1, 
			{x: this.width},
			{x: 0}), 0)*/
		}

		if (direction === 'prev') {
			const activeItemWidth = this.itemRefs[active].current.clientWidth
			let startPos = 0
			for(let i=0; i<nVisible; i++) {
				const k = (prev + i ) % n
				if (this.itemRefs[k]) {
					const itemWidth = this.itemRefs[k].current.clientWidth
					this.t.add(TweenMax.fromTo(this.itemRefs[k].current, 1, 
					{x: startPos},
					{x: startPos + activeItemWidth}), 0)
					startPos += itemWidth
				}
			}
			/*this.t.add(TweenMax.fromTo(this.itemRefs[prev].current, 1, 
			{x: 0},
			{x: this.width}), 0)*/
			
			this.t.add(TweenMax.fromTo(this.itemRefs[active].current, 1, 
			{x: -activeItemWidth},
			{x: 0}), 0)
		}
		}
		this.t.play()
	}
	
	render() {
		const {classes, active} = this.state
		const {children, itemStyle, renderNavigator} = this.props
		const nVisible = this.props.nVisible || 1
		if (!children) return null
		const n = children.length
		const carouselItemStyle = itemStyle || {
			width: (100 / nVisible) + '%',
			transform: `translate(${nVisible}00%, 0)`
		}
		
		return (
			<div ref={this.ref} className={classes}>
				<div className='track'>
					{children && children.map((child, i) => {
						if (this.itemRefs.length<i+1) this.itemRefs.push(React.createRef())
						return <div key={i} ref={this.itemRefs[i]} className={`item ${i===active?'active':''}`} style={carouselItemStyle}>{child}</div>
					})}
				</div>
				{renderNavigator
					?renderNavigator(this.prev, this.next)
					:<>
						{n>nVisible && <div className='arrow prev' onClick={this.prev}><FaIcon icon='faArrowLeft'/></div>}
						{n>nVisible && <div className='arrow next' onClick={this.next}><FaIcon icon='faArrowRight'/></div>}
					</>
				}
			</div>
		)
	}
}
export default Carousel
