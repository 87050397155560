import {menu} from '../config'

const isCustomNode = location => {
	let custom = null
	let path = []
	menu.forEach(item => {
		if (item.path === location) {
			custom = item.section
			path.push({lang: 'en', p: item.path})
			path.push({lang: 'ro', p: item._i18n.ro.path})
		}
		Object.keys(item._i18n).forEach(key => {
			if (item._i18n[key].path === location) {
				custom = item.section
				path.push({lang: 'en', p: item.path})
				path.push({lang: 'ro', p: item._i18n.ro.path})
			}
		})
	})
	if (location === '/' || location === '/en' || location === '/ro') {
		custom = '0'
		path.push({lang: 'en', p: '/en'})
		path.push({lang: 'ro', p: '/ro'})
	}
	if (custom) return {query: null, data: {results: [{_id: {$oid: 0}, type: 'node', bundle: 'section', section: custom, path}]}}
	return null
}
export default isCustomNode
