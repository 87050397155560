const hash = s => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return `m${hash}`
}

function memoize(f, single=true) {
	return function(...args) {
		const key = hash(JSON.stringify(args))
		if (single) {
			if (!f._memoKey || f._memoKey!==key) {
				//console.log('memoize', args)
				f._memoKey = key
				f._memo = f(...args)
			} //else console.log('memoized')
			return f._memo
		}
		
		if (!f._memoStore && !single) f._memoStore = {}
		if (!f._memoStore[key]) {
			//console.log('memoize', args)
			f._memoStore[key] = f(...args)
		} //else console.log('memoized')
		//console.log(f._memoStore)
		return f._memoStore[key]
	}
}
export default memoize
