import React from 'react'
import PageNode from '../page/PageNode'
import ArticleNode from '../article/ArticleNode'
import ArtistNode from '../artist/ArtistNode'
import SectionNode from '../section/SectionNode'
import SymposiumNode from '../symposium/SymposiumNode'
import WindowSize from '../../ui/WindowSize'
//import {pageView} from '../../lib/gtag'

const nodeTypes = {
	page: PageNode,
	article: ArticleNode,
	artist: ArtistNode,
	section: SectionNode,
	symposium: SymposiumNode
}

const NodeInner = ({status, data}) => {
	const {node} = data
	const bundle = (node && nodeTypes[node.bundle])?node.bundle:'article'
		
	const Comp = nodeTypes[bundle]
	if(!Comp) return null
	let classes = `node-content node-${bundle} node-${status}`
	if (node.section) classes += ` section-${node.section}`
	
	return (
		<div className={classes}>
			<WindowSize>
				{screen => <Comp data={{...data, screen}}/>}
			</WindowSize>
		</div>
	)
}

export default NodeInner
