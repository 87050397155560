import React from 'react'
import Field from '../../ui/Field'
import FaIcon from '../../ui/FaIcon'

const labels = {
	ro: {
		artists: 'Artiști participanți',
		text: 'Text critic',
		works: 'Lucrări realizate',
		th: 'Miniaturi',
		prev: 'Imaginea Anterioară',
		next: 'Imaginea Următoare',
		details: 'Detalii Simpozion'
	},
	en: {
		artists: 'Participant Artists',
		text: 'Symposium Comment',
		works: 'Works',
		th: 'Thumbnails',
		prev: 'Previous Image',
		next: 'Next Image',
		details: 'Symposium Details'
	}
}

class ArtistNode extends React.PureComponent {
	state = {
		view: 'gallery',
		active: 0,
		play: false,
	}

	componentWillUnmount() {
		this.unmounted = true
		clearInterval(this.i)
	}
	onPrev = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active - 1 + n ) % n})
		if (this.state.play) this.play()
	}
	onNext = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active + 1) % n})
		if (this.state.play) this.play()
	}
	play = e => {
		if (this.i) clearInterval(this.i)
		this.i = setInterval(() => {
			if (this.unmounted) return
			const n = this.props.data.node.img.length
			this.setState({active: (this.state.active + 1) % n})
		}, 5000)
		this.setState({play: true})
	}
	pause = e => {
		clearInterval(this.i)
		this.setState({play: false})
	}
	render() {
		const {active, play, view} = this.state
		const {language, node} = this.props.data
		//const {data, ...rest} = this.props
		//const dataExtra = {onWorkTitle:this.onWorkTitle, ...data}

		const galleryInfo = node.fieldInfo('img')
		galleryInfo.renderer = 'articleCarousel'
		galleryInfo._nowrap = true
		
		const meta = node.img[active].meta
		const workTitle = meta
			?((meta._i18n && meta._i18n[language])?meta._i18n[language].title:meta.title)
			:''

	return (
		<>
		<div key={2} className='region-title'><div className='region-in'>
			<Field key={0} field='title' entity={this.props.data.node} {...this.props}/>
			{workTitle && workTitle!=='.' && <div className='work-title'>{workTitle}</div>}
		</div></div>
		<div key={0} className='region-content'><div className='region-in'>
			<Field key={1} field='html' entity={this.props.data.node} {...this.props}/>
		</div></div>
		
		{this.props.data.node.img && <div key={1} className='region-gallery'><div className='region-in'>
			<Field key={0} field='img' entity={this.props.data.node} 
				fieldInfo={galleryInfo} active={active} {...this.props}/>
		</div></div>}

				<div key={3} className='region-links'><div className='region-in'>
					{view === 'info' && <div className='button' onClick={this.onGallery} >&lt; {labels[language].works}</div>}
					{view === 'gallery' && 
					<>
					{active+1}/{node.img.length}
					{play
						?<div className='button' onClick={this.pause}><FaIcon icon='faPause'/></div>
						:<div className='button' onClick={this.play}><FaIcon icon='faPlay'/></div>}
					<div className='button' onClick={this.onPrev} >&lt; {labels[language].prev}</div>
					|
					<div className='button' onClick={this.onNext} >{labels[language].next} &gt;</div>
					</>	
					}
					
				</div></div>
		</>
	)
	}
}
export default ArtistNode
