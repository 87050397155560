import React from 'react'
import Query from '../../lib/Query'
import ArticleNode from '../article/ArticleNode'
import Loading from '../lib/Loading'

const ArticleSection = props => {
	const {data} = props
	
	const section = data.node.section
	const q = {
		query: {bundle: 'article', section,
			$or: [
				{_lang: data.language},
				{[`_i18n.${data.language}`]: {$exists: true}}
			]
		},
		sort: {date: -1},
		limit: 1
	}
	
	return (
		<Query q={q} single setEntityInfo>
			{(node, status) => {
				if (status!=='fulfilled') return <Loading/>
				if (!node) return null
				return <ArticleNode data={{...data, node}}/>
			}}
		</Query>
	)
}

export default ArticleSection
