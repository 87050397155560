import React from 'react'
import ArticleSection from './ArticleSection'
import ArtistsSection from './ArtistsSection'
import SymposiumSection from './SymposiumSection'

const sections = {
	'0': ArticleSection,
	'1': ArticleSection,
	'2': ArticleSection,
	'3': ArtistsSection,
	'4': SymposiumSection,
	'5': ArticleSection,
}

const SectionNode = React.memo(props => {
	const {data} = props
	
	const section = data.node.section
	if (!sections[section]) return null
	const Comp = sections[section]
	return <Comp {...props}/>
})

export default SectionNode
