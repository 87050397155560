import React from 'react'
import Link from '../ui/Link'
import Field from '../ui/Field'
//import Icon from '../components/lib/Icon'
import FaIcon from '../ui/FaIcon'
import LazyImage from '../ui/LazyImage'
import BlockRenderer from '../components/lib/BlockRenderer'
import CarouselRenderer from '../components/lib/CarouselRenderer'
//import CardRenderer from '../components/lib/CardRenderer'
import CounterRenderer from '../components/lib/CounterRenderer'
import CounterCardRenderer from '../components/lib/CounterCardRenderer'
import Carousel from '../components/ui/Carousel'
import FormRenderer from '../components/ui/FormRenderer'
import Query from '../lib/Query'
import Region from '../ui/Region'

const LinkRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value) return null

    return <Link domRef={domRef} {...props}
                to={value.path || ''}
                >{value.label || ''}</Link>
}
const ImagesRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value) return null

	// items={value.img} fillWidth={value.f}

	return (
    	<div ref={domRef} {...props}>
    		<Carousel fillHeight={value.f}>
    			{value.img.map((img, i) => <LazyImage key={i} src={img}/>)}
    		</Carousel>
    	</div>
    )
}
const HeaderRenderer = ({domRef, field, value}) => {
    if(!value) return <div/>
    return <div ref={domRef} className={`field-${field.name}`}>
        	header
  	</div>
}
/*
const IconRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value || !value.icon) return null

    return <Icon domRef={domRef} name={value.icon} {...props}/>
}*/

const QuoteRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, className, data, ...props}) => {
    if(!value) return null

	let style = {}
	let classes = className
	if (value.bg && value.bg.length>0) {
		classes = `${className} has-bg`
		style = {
			height: 0,
			paddingTop: (value.bg[0].h / value.bg[0].w * 100) + '%'
		}
	}
	
    return (
    	<div ref={domRef} className={classes} {...props}><div className='quote-inner' style={style}>
	    	{value.bg && value.bg.length>0 && value.bg.map((bg, i) => <LazyImage key={i} className='bg' bg={true} src={bg}/>)}
	    	<div className='quote-content'>
       			<Field key={0} field='img' entity={entity} entityInfo={entityInfo} 
       				fieldInfo={{type: 'img'}} value={value.img} data={data} noadmin/>
       			<Field key={1} field='html' entity={entity} entityInfo={entityInfo} 
       				fieldInfo={{type: 'html'}} value={value.html} data={data} noadmin/>
       		</div>
    	</div></div>
    )
}
const PersonRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value) return null
	
    return (
    	<div ref={domRef} {...props}>
       		<Field key={0} field='img' entity={entity} entityInfo={entityInfo} 
       			fieldInfo={{type: 'img'}} value={value.img} data={data} noadmin/>
       		<div className='description'>
       			<div className='name'>{value.first_name} <span className='last-name'>{value.last_name}</span></div>
       			<div className='functie'>{value.functie}</div>
       			<div className='social'>
       				{value.facebook && <a href={value.facebook}><FaIcon icon='faFacebookF' size='16'/></a>}
       				{value.gplus && <a href={value.gplus}><FaIcon icon='faGooglePlusG' size='16'/></a>}
       				{value.pinterest && <a href={value.pinterest}><FaIcon icon='faPinterestP' size='16'/></a>}
       				{value.twitter && <a href={value.twitter}><FaIcon icon='faTwitter' size='16'/></a>}
       			</div>
       			<Field key={1} field='person-quote' entity={entity} entityInfo={entityInfo} 
       				fieldInfo={{type: 'html'}} value={value.quote} data={data} noadmin/>
       		</div>
    	</div>
    )
}
const SectionLinkRenderer = ({domRef, children, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value) return null

    return (
    	<Link domRef={domRef} {...props} to={value.path || ''}>
    		<div className='block-content'>
    			{children}
    		</div>
        </Link>
    )
}
const FragmentRenderer = ({domRef, field, value, fieldInfo, entity, entityInfo, data, ...props}) => {
    if(!value) return null
	
	const q = {
		collection: 'fragment',
		query: {_id: value.frag}
	}
    return (
    	<Query q={q} single={true}>
    	{(frag, status) => {
    		if (status!=='fulfilled' || !frag) return null
    		return (
    			<div ref={domRef} {...props}>
    				<Region entity={frag} data={data}/>
    			</div>
    		)
    	}}
    	</Query>
    )
}

const fieldRenderer = {
	block: BlockRenderer,
	articleCarousel: Carousel,
	carousel: CarouselRenderer,
	images: ImagesRenderer,
	link: LinkRenderer,
	header: HeaderRenderer,
	//card: CardRenderer,
	counter: CounterRenderer,
	counterCard: CounterCardRenderer,
	//icon: IconRenderer,
	quote: QuoteRenderer,
	person: PersonRenderer,
	sectionLink: SectionLinkRenderer,
	fragment: FragmentRenderer,
	contact: FormRenderer,
	form: FormRenderer
}
export {fieldRenderer}

