import React from 'react'
import {captcha} from '../config'
let ReCAPTCHA = null

class Captcha extends React.Component {
	state = {
		isReady: ReCAPTCHA!==null,
		
	}

	componentDidMount() {
		if (ReCAPTCHA) return
		this.reload()
	}

	componentWillUnmount() {
		this.unmounted = true
	}

	reload = () => {
		import('react-google-recaptcha').then(module => {
			if (this.unmounted) return
			ReCAPTCHA = module.default
			this.setState({isReady: true})
		})
		
	}
	
	onChange = captcha => {
		if (this.unmounted) return
		if (this.props.onChange) this.props.onChange(captcha)
	}	
	render() {
		const {language} = this.props
		if (!this.lang || this.lang!==language) {
			this.lang = language
			if (typeof window !== 'undefined') {
				window.recaptchaOptions = {
					lang: language,
					removeOnUnmount: true,
				}
			}
		}
		let props = {}
		if(typeof this.props.config === 'object') props = this.props.config
		return this.state.isReady
			?<ReCAPTCHA 
                	key={language} 
                	sitekey={captcha} onChange={this.onChange} {...props}/>
        	:null
	}
}

export default Captcha