import React from 'react'
import Link from '../../ui/Link'
import Counter from './Counter'

const CounterCardRenderer = ({domRef, field, value, fieldInfo, data}) => {
    if(!value) return <div/>
   
    const fieldProps = {}
    if (fieldInfo.id) fieldProps.id = fieldInfo.id
    const classes = `field-${field} card ` + (fieldInfo._class?fieldInfo._class:'')
//console.log(value, data)
	return <div ref={domRef} className={classes} {...fieldProps}>
		<div className='counters'>
			{value.counters && value.counters.map((counter, i) => 
				<div className='item' key={i}>
					<Counter label={counter.label} value={counter.count} data={data}/>
				</div>
			)}
		</div>
        {value.link && <Link to={value.link.path}>{value.link.label}</Link>}
    </div>
}
export default CounterCardRenderer