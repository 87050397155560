const fieldTypes = {
	//text: {dynamic: false},
	bool: {dynamic: false},
	img: {dynamic: false},
	'class': {dynamic: false},
	radio: {dynamic: false},
	list: {dynamic: false},
	obj: {dynamic: false},
	custom: {dynamic: false},
	images: {
		name: 'Imagini',
		type: 'obj',
		fields: [
			{name: 'img',	type: 'img', label: 'Imagine'},
			{name: 'c',	type: 'bool', label: 'Carusel', display: 'toggle'},
			{name: 'f',	type: 'bool', label: 'Fill height', display: 'toggle'},
		]
	},
	block: {
		name: 'Container',
		type: 'obj',
		isBlock: true,
		fields: [
			{name: 'label',	type: 'text', label: 'Etichetă'	},
			{name: 'bg',	type: 'img', label: 'Imagine'	},
			{name: 'p',	type: 'bool', label: 'Proporțional', display: 'toggle'},
			{name: 'f',	type: 'bool', label: 'Fullscreen fixed', display: 'toggle'},
			{name: 'c',	type: 'text', label: 'Carousel'	},
		]
	},
	carousel: {
		name: 'Carusel',
		type: 'obj',
		isBlock: true,
		fields: [
			{name: 'label',	type: 'text', label: 'Etichetă'	},
			{name: 'bg',	type: 'img', label: 'Imagine'	},
			{name: 'p',	type: 'bool', label: 'Proporțional', display: 'toggle'},
			{name: 'xss', type: 'text', label: 'XSS'},
			{name: 'xs', type: 'text', label: 'XS'},
			{name: 'sm', type: 'text', label: 'SM'},
			{name: 'md', type: 'text', label: 'MD'},
			{name: 'lg', type: 'text', label: 'LG'},
			{name: 'xl', type: 'text', label: 'XL'},
		]
	},
	link: {
		name: 'Legătură',
		type: 'obj',
		//dynamic: false,
		fields: [
			//{name: 'icon',	type: 'select', label: 'Iconiță', values: icons},
			{name: 'label',	type: 'text', label: 'Etichetă'	},
			{name: 'path', type: 'text', label: 'Legătură'},
		]
	},
	contact: {
        name: 'Contact',
        type: 'form',
        fields: [
            {name: 'intro', type: 'html', label: 'Introducere'},
            {name: 'thanks', type: 'html', label: 'Multumiri'},
            {name: 'labels', type: 'text', label: 'Etichete'},
        ],
        form: 'Contact',
        formFields: [
            {name: 'name',  type: 'text', label: 'Numele Dvs.'},
            {name: 'company',       type: 'text', label: 'Companie'},
            {name: 'email', type: 'text', label: 'E-mail'},
            {name: 'phone', type: 'text', label: 'Telefon'},
            {name: 'message', type: 'textarea', label: 'Mesaj'},
        ],
        captcha: {
        	theme: 'dark'
        }
    },

}

export {fieldTypes}
