import React from 'react'
import Query from '../../lib/Query'
import Link from '../../ui/Link'
import Loading from '../lib/Loading'

class IndexArtists extends React.Component {
	state = {
		section: 'event',
		q: {
			collection: 'node',
			query: {bundle: 'artist'},
			projection: {title: 1, path: 1},
			sort: {title: 1}
		}
	}
	componentWillUnmount() {
		this.unmounted = true
	}

	renderNode = (node, language) => {
		//console.log(node)
		let title = node.title
		let paths = node.path.filter(item => item.lang===language)
		let path = '/'
		if (paths.length>0) path = paths[0].p
		let classes = ''
		return <Link to={path} className={classes}>{title}</Link>
	}
	render() {
		const {language} = this.props
		const {q} = this.state

		return (
			<div id='index-artists'><div id='index-inner'>
				<Query q={q}>
					{(nodes, status, id, total) => (status!=='fulfilled' || !nodes)
						?<Loading/>
						:<div className='index-list'>
							{nodes.map((node, i) => <div key={i} className='index-item'>{this.renderNode(node, language)}</div>)}
						</div>
					}
				</Query>
			</div></div>
		)
	}
}

export default IndexArtists
