import React from 'react'
import {addLocationListener} from './history'
import LanguageContext from './LanguageContext'
import {LANGUAGES} from '../config'

class LanguageManager extends React.PureComponent {
	constructor(props) {
		super(props)

		let language = LANGUAGES?LANGUAGES[0]:null
		const location = typeof window === 'undefined'
			?global.location
			:window.location.pathname
		const toks = location.split('/')
		if (toks.length>1) {
			const lang = toks[1]
			if (LANGUAGES.indexOf(lang)>=0) language = lang
		}
		
		this.state = {
			language,
			changeLanguage: this.changeLanguage
		}
	}
	
	componentDidMount() {
		addLocationListener(this.handleLocationChange)
	}

	changeLanguage = language => {
		this.setState({language})
	}

	handleLocationChange = (location, search) => {
		const toks = location.split('/')
		if (toks.length<2) return
		const lang = toks[1]
		if (LANGUAGES.indexOf(lang)<0) {
			if (this.state.language!==LANGUAGES[0]) this.setState({language: LANGUAGES[0]})
		} else {
			if (lang!==this.state.language) this.setState({language: lang})
		}
	}

	render() {
		return (
			<LanguageContext.Provider value={this.state}>
				{this.props.children}
			</LanguageContext.Provider>	
		)
	}
	
}
export default LanguageManager