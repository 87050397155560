import React from 'react'
import {BASE} from '../config'
import FBVideo from '../ui/FBVideo'

let df = {}

const realPath = (path, style=null) => {
	if(!path) return null
	if (path.indexOf('http://')===0 || path.indexOf('https://')===0) return path
	if (style) {
		//let stylePath = path.replace('/files/', `/${style}/`)
		let stylePath = path.replace('/upload/', `/upload/styles/${style}/`)
		return `${BASE}${stylePath}`
	}
	return `${BASE}${path}`
}

const stripHTML = html => {
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText;
}

const fileTypes = {
	img: ['jpg', 'gif', 'png'],
	pdf: ['pdf'],
	doc: ['doc', 'docx'],
	xls: ['xls', 'xlsx'],
	
}
const getFileType = file => {
	let ext, fileType
	
	if(file.preview) ext = file.name.split('.').pop().toLowerCase()
	else {
		if (file.url) ext = file.url.split('.').pop().toLowerCase()
		else ext = file.split('.').pop().toLowerCase()
	}
	
	Object.keys(fileTypes).forEach(type => {if(fileTypes[type].includes(ext)) fileType = type})
	if (!fileType) return 'other'
	return fileType
}

const formatDate = (date, locale='ro') => {
	if (!date || !date.$date || !date.$date.$numberLong) return null
	//console.log(date)
	//let dateObj = Date.parse(date)
	let dateObj = parseInt(date.$date.$numberLong, 10)
	if (!df[locale]) df[locale] = new Intl.DateTimeFormat(locale)
	//console.log(df[locale].resolvedOptions())
	//console.log(df[locale].formatToParts(dateObj))
	return df[locale].format(dateObj)
}
const formatDateTime = (date, locale='ro') => {
	if (!date || !date.$date || !date.$date.$numberLong) return null
	//console.log(date)
	//let dateObj = Date.parse(date)
	let dateObj = parseInt(date.$date.$numberLong, 10)
	const df = new Intl.DateTimeFormat(locale, {year: 'numeric', month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric', second: 'numeric'})
	//console.log(df[locale].resolvedOptions())
	//console.log(df[locale].formatToParts(dateObj))
	return df.format(dateObj)
}
let separator
const getDateSeparator = (locale='ro') => {
	if (!separator) {
		const d = Date.now()
		if (!df[locale]) df[locale] = new Intl.DateTimeFormat(locale)
		const p = df[locale].formatToParts(d)
		const sep = p.filter(item => item.type==='literal')
		separator = sep[0].value
	}
	return separator
}
/*
const l = value => {
	if (!Array.isArray(value)) return value
	if (value.length === 0) return null
	if(value[0].hasOwnProperty('lang')) {
		if(value[0].hasOwnProperty('val')) return value[0].val
		return null
	}
	return value
}
*/

const renderVideo = (url, w) => {
	if (!url) return null
	
	const youtube_regex = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
	let match = url.match( youtube_regex)
	if( match && match[ 1 ].length === 11 ) {
    	const id = match[ 1 ]
    	return (
    		<div className='fluid'>
    		<iframe className='fluid-content' title='video'
    			src={`https://www.youtube.com/embed/${id}/?autoplay=1&autohide=1&modestbranding=1&rel=0&&showinfo=0`}
    			frameBorder="0" allowFullScreen></iframe>
    		</div>
    	)
	}
	
	const vimeo_regex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
	match = url.match( vimeo_regex)
	if( match ) {
    	const id = match[ 3 ]
    	return (
    		<div className='fluid'>
    			<iframe className='fluid-content' title='video'
					src={`https://player.vimeo.com/video/${id}?autoplay=1&title=0&byline=0&portrait=0`}
					frameBorder="0" allowFullScreen></iframe>
    		</div>
    	)
	}
	
	const facebook_regex = /(\.facebook\.)/
	match = url.match( facebook_regex)
	if( match ) {
		return <FBVideo url={url} w={w}/>
	}
	return null
}

const getVideoThumb = url => {
	if (!url) return null
	
	const youtube_regex = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
	let match = url.match( youtube_regex)
	if( match && match[ 1 ].length === 11 ) {
    	const id = match[ 1 ]
    	return `https://img.youtube.com/vi/${id}/0.jpg`
	}
	
	const vimeo_regex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
	match = url.match( vimeo_regex)
	if( match ) {
    	//const id = match[ 3 ]
    	return null
	}
	
	const facebook_regex = /\.facebook\..*(v|videos|story_fbid)(\/|=)(\d+)(\/|&)?/
	match = url.match( facebook_regex)
	if( match ) {
		const id = match[ 3 ]
		return `https://graph.facebook.com/${id}/picture`
	}
	return null
}

export {realPath, stripHTML, getFileType, formatDate, formatDateTime, getDateSeparator, renderVideo, getVideoThumb}
