import React from 'react'
import Field from '../../ui/Field'
import Hammer from 'hammerjs'
import FaIcon from '../../ui/FaIcon'
import {fieldTypes} from '../../admin/auth/conf/field'

const contact = {
	labels: 'Numele Dvs.,Companie,E-mail,Telefon,Mesaj,Trimite mesaj',
	_i18n: {
		en: {
			labels: 'Your name,Company,E-mail,Phone,Message,Send message'
		}
	}
}
const labels = {
	ro: {
		artists: 'Artiști participanți',
		text: 'Text critic',
		works: 'Lucrări realizate',
		th: 'Miniaturi',
		prev: 'Imaginea Anterioară',
		next: 'Imaginea Următoare',
		details: 'Detalii Simpozion'
	},
	en: {
		artists: 'Participant Artists',
		text: 'Symposium Comment',
		works: 'Works',
		th: 'Thumbnails',
		prev: 'Previous Image',
		next: 'Next Image',
		details: 'Symposium Details'
	}
}
class ArticleNode extends React.PureComponent {
	state = {
		active: 0,
		play: false
	}
	constructor(props) {
		super(props)
		this.track = React.createRef()
	}
	componentDidMount() {
		if (this.track.current) {
			this.hammer = new Hammer(this.track.current)
   	    	this.hammer.on('swiperight', this.onPrev)
       		this.hammer.on('swipeleft', this.onNext)
		}
	}
	componentWillUnmount() {
		this.unmounted = true
		clearInterval(this.i)
	}
	onPrev = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active - 1 + n ) % n})
		if (this.state.play) this.play()
	}
	onNext = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active + 1) % n})
		if (this.state.play) this.play()
	}
	play = e => {
		if (this.i) clearInterval(this.i)
		this.i = setInterval(() => {
			if (this.unmounted) return
			const n = this.props.data.node.img.length
			this.setState({active: (this.state.active + 1) % n})
		}, 5000)
		this.setState({play: true})
	}
	pause = e => {
		clearInterval(this.i)
		this.setState({play: false})
	}
	render() {
		const {active, play} = this.state
		const {language, node} = this.props.data
		
		const galleryInfo = node.fieldInfo('img')
		galleryInfo.renderer = 'articleCarousel'
		galleryInfo._nowrap = true
		
		return (
		<>
		<div key={0} className='region-content'><div className='region-in'>
			<Field key={0} field='title' entity={node} 
				 {...this.props}/>
			<Field key={1} field='html' entity={node} 
				 {...this.props}/>
		</div></div>
		
		{node.img && <div key={1} className='region-gallery' ref={this.track}><div className='region-in'>
			<Field key={0} field='img' entity={node} 
				fieldInfo={galleryInfo} hideMeta={true} active={active} {...this.props}/>
				</div>
			{node.section!=='2' && <div className='gallery-footer'>
					<div className='gallery-index'>{active+1}/{node.img.length}</div>
					{play
						?<div className='button' onClick={this.pause}><FaIcon icon='faPause'/></div>
						:<div className='button' onClick={this.play}><FaIcon icon='faPlay'/></div>}
					<div className='button' onClick={this.onPrev} >&lt; {labels[language].prev}</div>
					|
					<div className='button' onClick={this.onNext} >{labels[language].next} &gt;</div>
			</div>}
		</div>}
		
		{node.section==='5' && 
		<div key={2} className='region-contact'><div className='region-in'>
			<Field key={0} field='contact' entity={node} 
				fieldInfo={fieldTypes['contact']} value={contact} {...this.props}/>
		</div></div>}

			{node.section==='2' && 
				<div key={3} className='region-links'><div className='region-in'>
					<div className='gallery-index'>{active+1}/{node.img.length}</div>
					{play
						?<div className='button' onClick={this.pause}><FaIcon icon='faPause'/></div>
						:<div className='button' onClick={this.play}><FaIcon icon='faPlay'/></div>}
					<div className='button' onClick={this.onPrev} >&lt; {labels[language].prev}</div>
					|
					<div className='button' onClick={this.onNext} >{labels[language].next} &gt;</div>
			</div></div>}
		</>
		)
	}
}
export default ArticleNode
