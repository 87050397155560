import React from 'react'
import icons from '../../conf/icons'

const Icon = ({ domRef, field, value, fieldInfo, entity, data, ...props }) => {
	if (!value || !value.icon || !icons[value.icon]) return null
	const Comp = icons[value.icon]
	if (fieldInfo._nowrap) return <div ref={domRef} {...props}><Comp/></div>
	return <Comp {...props}/>
}

export default Icon
