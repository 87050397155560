import React from 'react'
import LazyImage from '../../ui/LazyImage'
import {screen} from '../../lib/screen'
import Carousel from '../ui/Carousel'

const CarouselRenderer = props => {
	const {domRef, field, value, id, className, entity, entityInfo, fieldInfo, data, ...other} = props

    if(!value) return <div/>

	let styleBlock = {}
	let classes = className
	const carouselProps = {
		nVisible: value[screen.MQ]
	}
	if (value.p &&
		value.bg && value.bg.length>0 &&
		value.bg[0].w>0 && value.bg[0].h>0) {
		let paddingTop = (value.bg[0].h / value.bg[0].w * 100) + '%'
		classes += ' carousel-proportional'

		if (value.bg[0].srcset) {
			value.bg[0].srcset.forEach(srcset => {
				if (srcset.mq.indexOf(screen.MQ)>=0 &&
					srcset.h > 0 &&
					srcset.w > 0) {
					paddingTop = (srcset.h / srcset.w * 100) + '%'
				}
			})
		}

		styleBlock = {
			height: 0,
			paddingTop
		}
		
		carouselProps.nostyle = true
	}
	
    return <div ref={domRef} id={id} className={classes} {...other}><div className='carousel-inner' style={styleBlock}>
    	{value.bg && value.bg.length>0 && value.bg.map((bg, i) => <LazyImage key={i} className='bg' bg={true} src={bg}/>)}
    	<Carousel {...carouselProps}>
			{props.children}
    	</Carousel>
    </div></div>
}
export default CarouselRenderer

