import React from 'react'
import {TweenMax} from 'gsap'
import fastdom from 'fastdom'

class Counter extends React.Component {
	constructor(props) {
		super(props)
		this.ref = React.createRef()
		this.state = {val: 0}
	}
	componentDidUpdate() {
		const {value, data} = this.props
		const {visible} = data
		
    	const n = parseInt(value, 10)
    	
    	if (this.counted || !visible || n<=0) return
    	this.counted = true
    	
    	let counter = {val: 0}
    	TweenMax.to(counter, 5, {delay: 0.5, val: n, roundProps:"val",
    		onUpdate: () => {
    			fastdom.mutate(() => {
    				if (this.unmounted) return;
    				this.ref.current.innerHTML=counter.val.toLocaleString()
    			})
    		},
    		onComplete: () => {
    			if (this.unmounted) return;
				this.setState({val: n})    			
    		}
    	})
	}
	componentWillUnmount() {
		this.unmounted = true
	}
	render() {
		const {label} = this.props
		const {val} = this.state
		
		return (
			<div className='counter'>
				<div className='count' ref={this.ref}>{val}</div>
				{label && <div className='count-label'>{label}</div>}
			</div>
    	)
	}
}
export default Counter
