import {axios, API} from '../config'
import {progressStart, progressDone} from './progress'

const DELTA = 300000

let storage = {
	queries: {},
	promises: {},
	t: {},
	willFetch: false,
	active: [],
	qid: -1,
	hydrated: false
}

const hashstr = s => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return 'h' + hash
}

const doFetch = () => {
	const queries = storage.queries
	const promises = storage.promises
	storage.queries = {}
	storage.promises = {}
	storage.willFetch = false
	
	const keys = Object.keys(queries)
	const options = keys.map(key => queries[key])
	progressStart()
	return axios.post(`${API}/datamulti`, {params: {options}})
		.then(response => {
			progressDone()
			//console.log(response)
			if (response.status!==200 || typeof response.data === 'string') {
				keys.forEach ((key, i) => {
					for (let p of promises[key]) {
						p(null)
					}
				})
				return
			}
			keys.forEach ((key, i) => {
				const data = response.data[i]
				storage.t[key] = Date.now()
				if(typeof window !== 'undefined') {
					sessionStorage.setItem(key, JSON.stringify(data))
				} else {
					global.store[key] = JSON.stringify(data)
				}
				for (let p of promises[key]) {
					setTimeout(() => {
						p(data)
					})
				}
			})
		})
		.catch(error => {console.log(error)})
}

const query = (q) => {
	const key = hashstr(JSON.stringify(q))
	try {
		if(typeof window !== 'undefined') {
			if (window.isHydrating) {
				if (window.initialStore[key]) {
					storage.t[key] = Date.now()
					sessionStorage.setItem(key, window.initialStore[key])
					return(JSON.parse(window.initialStore[key]))
				}
			}
			const ret = JSON.parse(sessionStorage.getItem(key))
			if(ret) {
				if(storage.t[key] && (Date.now() - storage.t[key] < DELTA)) return ret
				else {
					delete storage.t[key]
				}
			}
		} else {
			if (global.store[key]) {
				return JSON.parse(global.store[key])
			}
		}
	} catch (e) {console.log(e)}
	storage.queries[key] = q
	if (!storage.willFetch) {
		storage.willFetch = true
		setTimeout(() => {doFetch()})
	}
	const promise = new Promise((resolve, reject) => {
		const cb = data => resolve(data)
		if (!storage.promises[key]) storage.promises[key] = []
		storage.promises[key].push(cb)
	})
	if (typeof window === 'undefined') {
		global.storePromises.push(promise)
	}
	return promise
}

const registerQuery = (q, cb) => {
	const key = hashstr(JSON.stringify(q))
	storage.qid += 1
	storage.active.push({qid: storage.qid, key, q, cb})
	return storage.qid
}
const unregisterQuery = qid => {
	storage.active = storage.active.filter(item => item.qid!==qid)
}

const queryRefreshAll = () => {
	sessionStorage.clear()
	if (!storage.willFetch) {
		storage.willFetch = true
		setTimeout(() => {doFetch()})
	}
	storage.active.forEach(item => {
		storage.queries[item.key] = item.q
		if (!storage.promises[item.key]) storage.promises[item.key] = []
		storage.promises[item.key].push(item.cb)
	})
}

const updateMany = (update, cb=null) => {
	let collection = update.collection || 'node'
	return axios.put(`${API}/datamany/${collection}`, update)
		.then(response => {
			if(cb) cb(response)
		})
		.catch(error => console.log(error))
}

const update = (update, cb=null) => {
		let id
		if(update.id) id = update.id
		else {
			if(update._id) id = update._id['$oid']
			else return
		}
		let collection = update.collection || 'node'
		return axios.put(`${API}/data/${collection}/${id}`, update)
			.then(response => {
				if(cb) cb(response)
			})
			.catch(error => console.log(error))
}
const insert = (update, cb=null) => {
		let collection = update.collection || 'node'
		return axios.post(`${API}/data/${collection}`, update)
			.then(response => {
				if(cb) cb(response)
				return response
			})
			.catch(error => console.log(error))
}
const sendForm = (form, cb=null) => {
		return axios.post(`${API}/form`, form)
			.then(response => {
				if(cb) cb(response)
				return response
			})
			.catch(error => console.log(error))
}

const findFilesRecursive = (entity, files) => {
	if(!entity) return
	if (typeof entity !== 'object') return
	
	if (entity.url) {
		files.push(entity.url)
		return
	}
	if (Array.isArray(entity)) {
		entity.forEach(item => findFilesRecursive(item, files))
		return
	}
	Object.keys(entity).forEach(key => findFilesRecursive(entity[key], files))
}
const findFiles = entity => {
	let files = []
	findFilesRecursive(entity, files)
	return files
}
const getEntity = (_id, collection='node') => {
	const options = [{
		collection,
		query: {_id}
	}]
	return axios.post(`${API}/datamulti`, {params: {options}})
			.then(response => {
				if (response.data && 
					response.data.length===1 && 
					response.data[0].results && 
					response.data[0].results.length===1) return response.data[0].results[0]
				return null
			})
			.catch(error => {console.log(error); return null})
}
const hash = q => hashstr(JSON.stringify(q))
export {query, registerQuery, unregisterQuery, queryRefreshAll, update, insert, updateMany, findFiles, getEntity, hash, sendForm}
