import React from 'react'
import Query from '../../lib/Query'
import {Link, FaIcon} from '../../ui'
import Loading from '../lib/Loading'

class IndexArticles extends React.Component {
	state = {
		open: false
	}
	toggle = e => {
		this.setState({open: !this.state.open})
	}
	close = e => {
		this.setState({open: false})
		return true
	}
	
	renderNode = (node, language, location) => {
		let title = node.title
		let paths = node.path.filter(item => item.lang===language)
		let path = '/'
		if (paths.length>0) path = paths[0].p
		if (node._i18n && node._i18n[language]) {
			title = node._i18n[language].title
		}
		let classes = ''
		if (path===location) classes = 'active'
		return <Link to={path} className={classes}
			onActivate={this.close}>{title}</Link>
	}
	render() {
		const {language, node} = this.props
		const {open} = this.state
		
		const section = node?node.section:null
		const q = section
			?{
			collection: 'node',
			query: {bundle: 'article', section,
				$or: [
					{_lang: language},
					{[`_i18n.${language}`]: {$exists: true}}
				]},
			projection: {title: 1, path: 1, _i18n: 1},
			sort: {date: -1}
			}
			:null
		const location = typeof window === 'undefined'
			?global.location
			:window.location.pathname
		
		return (
			<>
			<div id='handle-index' className={open?'open':''} onClick={this.toggle}><FaIcon icon={open?'faAngleLeft':'faAngleRight'}/></div>
			<div id='index-articles' className={open?'open':''}><div id='index-inner'>
				<Query q={q}>
					{(nodes, status, id, total) => (status!=='fulfilled' || !nodes)
						?<Loading/>
						:<ul>
							{nodes.map((node, i) => <li key={i}>{this.renderNode(node, language, location)}</li>)}
						</ul>
					}
				</Query>
			</div></div>
			</>
		)
	}
}

export default IndexArticles
