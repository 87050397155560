import React from 'react'
import LazyImage from '../../ui/LazyImage'
import {TimelineMax, TweenMax} from 'gsap' 

const labels = {
	ro: {
		tehnica: 'Tehnica',
		category: 'Categorie',
		an: 'An',
		size: 'Dimensiuni'
	},
	en: {
		tehnica: 'Technique',
		category: 'Category',
		an: 'Year',
		size: 'Size'
	}
}

class Carousel extends React.PureComponent {
	constructor(props) {
		super(props)
		//this.ref = React.createRef()
		this.state = {
			width: 0, 
			height: 0
		}
		this.itemRefs = []
	}
	
	componentDidMount() {
		this.onResize()
		this.move(-1, this.props.active)
	}
	
	componentWillUnmount() {
		this.unmounted = true
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.active !== prevProps.active) this.move(prevProps.active, this.props.active)
		if (prevProps.data && prevProps.data.screen && prevProps.data.screen.W!==this.props.data.screen.W) {
			setTimeout(() => {
				if (this.unmounted) return
				this.onResize()
			}, 1000)
		}
	}
	
	onResize = () => {
		if (!this.props.domRef || !this.props.domRef.current) {
			setTimeout(() => {
				if (this.unmounted) return
				this.onResize()
			}, 5000)
			return
		}
		const {width, height} = this.props.domRef.current.getBoundingClientRect()
		//console.log(this.props.domRef, width, height)
		this.setState({width, height})
	}
	
	move = (prev, active) => {
		const prevItem = prev>=0
			?this.itemRefs[prev].current
			:null
		const prevImg = prev>=0
			?prevItem.querySelector('.lazy')
			:null
		const activeItem = this.itemRefs[active].current
		const activeImg = activeItem.querySelector('.lazy')
					
		if (this.t) this.t.progress(1, false)
		
		this.t = new TimelineMax()
		this.t.pause()

		if (prevImg) {
			this.t.add(TweenMax.fromTo(prevItem, 1, 
			{opacity: 1},
			{opacity: 0}), 0)
			this.t.add(TweenMax.fromTo(prevImg, 0.2, 
			{backgroundColor: 'rgba(60, 39, 0, 0)'},
			{backgroundColor: 'rgba(60, 39, 0, 1)'}), 0)
		}
		
		this.t.add(TweenMax.fromTo(activeItem, 1, 
		{opacity: 0},
		{opacity: 1}), 0)
		this.t.add(TweenMax.fromTo(activeImg, 1, 
		{backgroundColor: 'rgba(60, 39, 0, 1)'},
		{backgroundColor: 'rgba(60, 39, 0, 0)'}), 0.5)
		
		this.t.play()
	}
	
	
	render() {
		const {domRef, entity, entityInfo, field, value, active, hideMeta, fieldInfo, data, ...props} = this.props
		const {language} = data
		const {width, height} = this.state

		return (
			<div ref={domRef} {...props}>
				{value.map((img, i) => {
					if (this.itemRefs.length<i+1) this.itemRefs.push(React.createRef())
					
					let w = width
					let h = width * img.h / img.w
					if (h>height) {
						h = height
						w = height * img.w / img.h
					}
					const style = {width: w, height: h}
					let classes = 'item'
					if (active === i) classes = 'item active'
					
					return (
					<div key={i} ref={this.itemRefs[i]} className={classes}>
	    				<LazyImage bg={true} src={img} style={style}/>
	    				{!hideMeta && img.meta && <div className='meta'>
	    					{(img.meta._i18n && img.meta._i18n[language])
	    						?<>
	    							{img.meta._i18n[language].tehnica && <><div className='label'>{labels[language]['tehnica']}</div><div className='value'>{img.meta._i18n[language].tehnica}</div></>}
	    							{img.meta._i18n[language].category && <><div className='label'>{labels[language]['category']}</div><div className='value'>{img.meta._i18n[language].category}</div></>}
	    							{img.meta._i18n[language].an && <><div className='label'>{labels[language]['an']}</div><div className='value'>{img.meta._i18n[language].an}</div></>}
	    							{img.meta._i18n[language].size && <><div className='label'>{labels[language]['size']}</div><div className='value'>{img.meta._i18n[language].size}</div></>}
	    						</>
	    						:<>
	    							{img.meta.tehnica && <><div className='label'>{labels[language]['tehnica']}</div><div className='value'>{img.meta.tehnica}</div></>}
	    							{img.meta.category && <><div className='label'>{labels[language]['category']}</div><div className='value'>{img.meta.category}</div></>}
	    							{img.meta.an && <><div className='label'>{labels[language]['an']}</div><div className='value'>{img.meta.an}</div></>}
	    							{img.meta.size && <><div className='label'>{labels[language]['size']}</div><div className='value'>{img.meta.size}</div></>}
	    						</>}
	    				</div>}
	    			</div>
	    			)
				})}
			</div>
		)
	}
}
export default Carousel
