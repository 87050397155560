import {LANGUAGES} from '../config'
import isCustomNode from '../conf/node'
import {query, registerQuery, unregisterQuery} from './api'

let qid = null
const pathField = LANGUAGES?'path.p':'path'
const buildQuery = location => ({
	query: {
		$or: [
			{[pathField]: location}, 
			{alias: location}
		]
	}
})

const getNode = (location, cb=null) => {
	if (qid) unregisterQuery(qid)
	qid = null
	
	let res = isCustomNode(location)
	if (res) return res

	const q = buildQuery(location)
	const data = query(q)
	
	if (cb) qid = registerQuery(q, cb)
	return {query: q, data}
}

export default getNode
