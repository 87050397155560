import React from 'react';
import Logo from './Logo'
import Menu from './Menu'

const Header = React.memo(({language}) => 
	<header id='header'>
		<Logo language={language}/>
		<Menu language={language}/>
	</header>)
export default Header
