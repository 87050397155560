import React from 'react'
import Field from '../../ui/Field'
import Link from '../../ui/Link'
import FaIcon from '../../ui/FaIcon'

const labels = {
	ro: {
		artists: 'Artiști participanți',
		text: 'Text critic',
		works: 'Lucrări realizate',
		th: 'Miniaturi',
		prev: 'Imaginea Anterioară',
		next: 'Imaginea Următoare',
		details: 'Detalii Simpozion'
	},
	en: {
		artists: 'Participant Artists',
		text: 'Symposium Comment',
		works: 'Works',
		th: 'Thumbnails',
		prev: 'Previous Image',
		next: 'Next Image',
		details: 'Symposium Details'
	}
}

class SymposiumNode extends React.PureComponent {
	state = {
		view: 'gallery',
		active: 0,
		play: false,
		workInfo: false
	}
	componentWillUnmount() {
		this.unmounted = true
		clearInterval(this.i)
	}
	onInfoArtists = e => {
		this.setState({view: 'info'})
	}
	onInfoText = e => {
		this.setState({view: 'text'})
	}
	onWorkInfo = e => {
		this.setState({workInfo: !this.state.workInfo})
	}
	onGallery = e => {
		this.setState({view: 'gallery'})
	}
	onPrev = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active - 1 + n ) % n})
		if (this.state.play) this.play()
	}
	onNext = e => {
		const n = this.props.data.node.img.length
		this.setState({active: (this.state.active + 1) % n})
		if (this.state.play) this.play()
	}
	play = e => {
		if (this.i) clearInterval(this.i)
		this.i = setInterval(() => {
			if (this.unmounted) return
			const n = this.props.data.node.img.length
			this.setState({active: (this.state.active + 1) % n})
		}, 5000)
		this.setState({play: true})
	}
	pause = e => {
		clearInterval(this.i)
		this.setState({play: false})
	}
	renderArtist = (a, i) => {
		const {language} = this.props.data
		let path = a._path.filter(p => p.lang===language)
		if (path.length===1) path = path[0].p
		else {
			if (a._path.length>0) path = a._path[0].p
			else path = '/'
		}
		//console.log(a)
		return <Link key={i} to={path}>{a.title}</Link>
	}
	renderArtists = () => {
		let artistsMap = {}
		if (!this.props.data.node.img) return null
		this.props.data.node.img.forEach(img => {
			if (img.meta && img.meta.artist) artistsMap[img.meta.artist.id] = img.meta.artist
		})
		const artists = Object.keys(artistsMap).map(id => artistsMap[id]).sort((a,b) => a.title.localeCompare(b.title))
		return (
			<div>
				{artists.map((a, i) => this.renderArtist(a, i))}
			</div>
		)
	}
	
	render() {
		const {view, active, play, workInfo} = this.state
		const {language, node} = this.props.data
		
		const galleryInfo = node.fieldInfo('img')
		galleryInfo.renderer = 'articleCarousel'
		galleryInfo._nowrap = true
		
		const meta = node.img?node.img[active].meta:null
		const workTitle = meta
			?((meta._i18n && meta._i18n[language])?meta._i18n[language].title:meta.title)
			:''
		const artist = meta
			?((meta.artist)?meta.artist.title:'')
			:''
			
		return (
			<>
				<div key={2} className='region-title'><div className='region-in'>
					<Field key={0} field='title' entity={this.props.data.node} {...this.props}/>
					{artist && artist!=='.' && <div className='artist-title'> - {artist}</div>}
					{workTitle && workTitle!=='.' && <div className='work-title'>{workTitle}</div>}
				</div></div>
		
				{this.props.data.node.img && 
				<div key={1} className={`region-gallery ${workInfo?'work-info':''}`}><div className='region-in'>
					<Field key={0} field='img' entity={this.props.data.node} 
						fieldInfo={galleryInfo} active={active} {...this.props}/>
				</div></div>}
				
				<div key={4} className={`region-info view-${view}`}><div className='region-in'>
					<div className='index-artists'>
						<h2>{labels[language].artists}</h2>
						{this.renderArtists()}
					</div>
					<div className='text-critic'>
						<h2>{labels[language].text}</h2>
						<Field key={1} field='html' entity={this.props.data.node} {...this.props}/>
					</div>
				</div></div>
				<div key={3} className='region-links'><div className='region-in'>
					{view !== 'gallery' && <div className='button' onClick={this.onGallery} >&lt; {labels[language].works}</div>}
					{view === 'gallery' && 
					<>
					{active+1}/{node.img?node.img.length:0}
					{play
						?<div className='button' onClick={this.pause}><FaIcon icon='faPause'/></div>
						:<div className='button' onClick={this.play}><FaIcon icon='faPlay'/></div>}
					<div className='button' onClick={this.onPrev} ><FaIcon icon='faAngleLeft'/><span>{labels[language].prev}</span></div>
					|
					<div className='button' onClick={this.onNext} ><span>{labels[language].next}</span><FaIcon icon='faAngleRight'/></div>
					<div className='button details artists' onClick={this.onInfoArtists} ><FaIcon icon='faPalette'/><span>{labels[language].details}</span></div>
					<div className='button details text' onClick={this.onInfoText} ><FaIcon icon='faFeather'/><span>{labels[language].details}</span></div>
					<div className='button details work-info' onClick={this.onWorkInfo} ><FaIcon icon='faInfoCircle'/></div>
					</>	
					}
					
				</div></div>
			</>
		)
	}
}
export default SymposiumNode
