import React from 'react'
import throttle from 'lodash.throttle'

class ScrollManager extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			scroll: 0
		}
		if (typeof window !== 'undefined') {
			this.state = {
				scroll: window.scrollY
			}
		}
	}
	componentDidMount() {
		const scroll = window.scrollY
		window.addEventListener('scroll', this.onScroll)
		if (scroll!==this.state.scroll) this.setState({scroll})
	}

	componentWillUnmount() {
        this.unmounted = true
		window.removeEventListener('scroll', this.onScroll)
    }
    
	onScroll = throttle(() => {
		if (this.unmounted) return
		this.setState({scroll: window.scrollY})
	}, 50)
    
	render() {
		return this.props.children(this.state.scroll)
	}
}
export default ScrollManager