import React from 'react'
import Field from '../Field'

const Any = ({domRef, className, entity, field, value, fieldInfo, data, ...props}) => {
	//console.log(value, fieldInfo)
	if (!value || value.length===0 || fieldInfo.fields===undefined) return null
	
	return (
		<>
		{fieldInfo.fields.filter(f => f.display===undefined || f.display).map((f, i) => 
			<Field key={i} value={value[f.name]} field={f.name} fieldInfo={f} data={data} {...props} noadmin />
		)}</>
	)
}
export default Any
