import {entityFields} from './entity'
import {nodeBundles, entityTypes} from './entityAdmin'
import {fieldTypes} from './field'

const wrapInfo = (origEntity) => {
	if (!origEntity || origEntity._info) return origEntity
	
	const _info = {}
	const entity = {...origEntity, _info}
//console.log(entity)
	_info.collection = entity.type==='node' 
		?'node' 
		:((entityTypes[entity.type] && entityTypes[entity.type].collection)
				?entityTypes[entity.type].collection
				:entity.type)

	_info.fields = ((entity.type==='node')?entityFields[entity.bundle]:entityFields[entity.type]) || []
	_info.defaultFields = _info.fields.map(f => f.name)
	
	if (entity._e) {
		entity._e.forEach(field => {
			let found = false
			_info.fields = _info.fields.map(f => {
					if (f.name===field.name) {
						found = true
						return Object.assign({}, f, field)
					}
					return f
				})
			if (!found) _info.fields.push(field)
		})
	}
	_info.fields.forEach((f,i) => _info.fields[i] = Object.assign({}, fieldTypes[f.type]?fieldTypes[f.type]:{}, f))
	
	const typeInfo = (entity.type === 'node')
		?nodeBundles[entity.bundle]
		:entityTypes[entity.type]
	if (typeInfo) Object.keys(typeInfo).forEach(key => {
		if (key==='fields') {
			Object.keys(typeInfo.fields).forEach(fieldName => 
				_info.fields = _info.fields.map(field => 
					field.name===fieldName?Object.assign({}, field, typeInfo.fields[fieldName]):field)
			)
		} else {
			if (!_info[key]) _info[key] = typeInfo[key]
		}
	})
	
	entity.fieldInfo = function(fieldName) {
		const fields = this._info.fields.filter(f => f.name===fieldName)
		if (fields.length===0) return null
		return fields[0]
	}
	entity.unwrap = function() {
		const {_info, fieldInfo, unwrap, ...orig} = this
		return orig
	}
	return entity
}
export default wrapInfo
