const values = {}
let key = 0
let subscribers = {}

const subscribe = (topic, cb) => {
	if (!subscribers[topic]) subscribers[topic] = {}
	key++
	subscribers[topic][key] = cb
	return key	
}

const unsubscribe = (topic, key) => delete subscribers[topic][key]

const publish = (topic, value) => {
	if (values[topic] === value) return
	//console.log(topic, value)
	values[topic] = value
	if(!subscribers[topic]) return
	for (let key in subscribers[topic]) {
		subscribers[topic][key](topic, value)
	}
}
const get = topic => values[topic]

export {subscribe, unsubscribe, publish, get}
