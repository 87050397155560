import React from 'react'
import AuthContext from './AuthContext'
import jwtDecode from 'jwt-decode'
import {ORIGIN, axios, auth0} from '../config'
import User from './User'
let Auth0Lock = null

const setAxiosToken = token => {
	if(!token) axios.defaults.headers.common['Authorization'] = null
	else axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

class Auth extends React.PureComponent {
    constructor(props) {
    	super(props)
    	this.state = {
    		user: null,
    		login: this.login,
    		logout: this.logout
    	}
		this.state.user = this.loadInitialState()
    }

	loadInitialState = () => {
		if (typeof window === 'undefined') return null
    	let profile = localStorage.getItem('profile')
    	let token = localStorage.getItem('id_token')
    
    	if(profile && token) {
    		const dtoken = jwtDecode(token)
    		//console.log(dtoken, auth0, Date.now()/1000)
    		if(dtoken.aud!==auth0 || dtoken.exp < Date.now()/1000) {
				localStorage.removeItem('id_token')
   				localStorage.removeItem('profile')
   			} else {
   				try {
   					profile = JSON.parse(profile)
   					
   					setAxiosToken(token)
   					return new User(profile)
   				} catch (e) {
   					console.log(e)
   					localStorage.removeItem('id_token')
   					localStorage.removeItem('profile')
   				}
   			}
    	}
    	return null
	}

	handleAuthentication = authResult => {
		this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
			if (error) {
        		console.log(error);
    			alert(
        			'Error: ' + error.error + '. Check the console for further details.'
    			);
    			return
			}
		
			setAxiosToken(authResult.idToken)
			const user = new User(profile)
			const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
			localStorage.setItem('expires_at', expiresAt)
			localStorage.setItem('profile', JSON.stringify(profile))
			localStorage.setItem('id_token', authResult.idToken)
			
			this.setState({user})
		})
	}

	doLogin = () => {
		if (!this.lock) {
            this.lock = new Auth0Lock(auth0, 'linuxship.eu.auth0.com', {
            	autoclose: true,
            	autofocus: true,
            	language: 'ro',
            	auth: {
            		redirect: false,
            		redirectUrl: ORIGIN + '/login',
            		responseType: 'token id_token',
					audience: 'https://linuxship.eu.auth0.com/userinfo',
					scope: 'openid profile email roles',
            	}
            })
            this.lock.on("authenticated", this.handleAuthentication)
		}
        this.lock.show()
	}

	loadLock = () => {
		import ('auth0-lock').then(auth0LockModule => {
			if (this.unmounted) return
			Auth0Lock = auth0LockModule.default
			this.doLogin()
		})
	}

    login = () => {
    	if (Auth0Lock) this.doLogin()
       	else this.loadLock()
    }
    logout = () => {
       	//clearTimeout(this.tokenRenewalTimeout)
		localStorage.removeItem('id_token')
   		localStorage.removeItem('profile')
		localStorage.removeItem('expires_at')
		this.setState({
			user: null,
		})
    }

	componentWillUnmount() {
		this.unmounted = true
    }

	render() {
		return (
			<AuthContext.Provider value={this.state}>
				{this.props.children}
			</AuthContext.Provider>	
		)
	}
}
export default Auth
