import React, {lazy, Suspense} from 'react'

const FieldMenu = lazy(() => import('../admin/auth/field/FieldMenu'));

const FieldAdminMenu = ({data}) => {
	const {hover, user, menu} = data
	if(!user || !hover || !menu || menu.length===0) return null
	
	return <Suspense fallback={<div className='field-admin-menu'></div>}><FieldMenu user={user} menu={menu}/></Suspense>
}
export default FieldAdminMenu
