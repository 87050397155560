import React from 'react'
import {subscribe, unsubscribe} from '../lib/pubsub'
import {screen} from '../lib/screen'

class WindowSize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			W: screen.W,
			H: screen.H
		}
	}
	componentDidMount() {
		const {W, H} = this.state
		this.resizeId = subscribe('resize', this.onResize)
		if (screen.W!==W || screen.H!==H) this.onResize('resize', screen)
	}

	componentWillUnmount() {
        this.unmounted = true
        unsubscribe('resize', this.resizeId)
    }
    
    onResize = (topic, {W, H}) => {
    	if (this.unmounted) return
    	this.setState({W, H})
    }

	render() {
		return this.props.children(screen)
	}
}
export default WindowSize
