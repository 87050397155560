import React from 'react'
import appIcons from '../conf/fa-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'
import {faWindowMaximize} from '@fortawesome/free-solid-svg-icons/faWindowMaximize'
import {faWindowMinimize} from '@fortawesome/free-solid-svg-icons/faWindowMinimize'
import {faWindowRestore} from '@fortawesome/free-solid-svg-icons/faWindowRestore'
import {faLock} from '@fortawesome/free-solid-svg-icons/faLock'
import {faLockOpen} from '@fortawesome/free-solid-svg-icons/faLockOpen'

const icons = {
	...appIcons,
	faBars,
	faTimes,
	faWindowMaximize,
    faWindowMinimize,
    faWindowRestore,
    faLock,
    faLockOpen,
}
const FaIcon = ({icon, size}) => {
	if (!icons[icon]) return null
	
	const iconData = icons[icon].icon
	
	const width = iconData[0]
	const height = iconData[1]
	const path = iconData[4]
	let style = {}
	if (size) {
		style = {
			width: `${size}px`,
			height: `${size}px`
		}
	}
	
	return (
		<svg style={style} viewBox={`0 0 ${width} ${height}`}> xmlns="http://www.w3.org/2000/svg">
			<path fill='currentColor' d={path}/>
		</svg>
	)
}
export default FaIcon
