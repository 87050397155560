import React from 'react'
import {FaIcon, Link} from '../../ui'
import {menu} from '../../config'

class Menu extends React.PureComponent {
	state = {
		open: false
	}
	toggle = e => {
		this.setState({open: !this.state.open})
	}
	
	close = e => {
		this.setState({open: false})
		return true
	}
	
	renderMenuItem = (item, i) => {
		const {language} = this.props
		
		let path = item.path
		let title = item.title
		if (item._i18n[language]) {
			path = item._i18n[language].path
			title = item._i18n[language].title
		}
		return <Link key={i} to={path} className='menu-item'
			onActivate={this.close}>{title}</Link>
	}	
	
	render() {
		const {open} = this.state
		
		return (
			<>
			<div className={`menu-button ${open?'open':''}`} onClick={this.toggle}>
				<FaIcon icon={open?'faTimes':'faBars'}/>
			</div>
			<div className={`menu ${open?'open':''}`}>
				{menu.map(this.renderMenuItem)}
			</div>
			</>
		)
	}
}
export default Menu
