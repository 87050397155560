import {
	nodeBundles as customNodeBundles, 
	entityTypes as customEntityTypes
} from '../../../conf/entityAdmin'
import {createPermission, isEditor} from '../lib/access'
import auth from '../../../authorize'

const entitySettings = [
	{name: '_nodel', type: 'bool', label: 'Nu se poate șterge', widget: 'toggle', require: 'admin'},
	{name: '_cssid', type: 'text', label: 'CSS ID'},
	{name: '_class', type: 'text', label: 'Clasa CSS'},
]

const nodeBundles = Object.assign({
}, customNodeBundles)
Object.keys(nodeBundles).forEach(bundle => {
	nodeBundles[bundle].settings = [...entitySettings, ...nodeBundles[bundle].settings||[]]
})
const entityTypes = Object.assign({
}, customEntityTypes)
Object.keys(entityTypes).forEach(type => {
	entityTypes[type].settings = [...entitySettings, ...entityTypes[type].settings||[]]
})

const entityAccess = (user, op, data) => {
	if (!op || !data) return false
	if (!user) return false
	if (isEditor()) return true
	console.log(auth)
	switch (op) {
		case 'create': {
			if (!auth.entity || !auth.entity[data] || !auth.entity[data].create) return false
			let authRoles = auth.entity[data].create

			let ret = false
			user.roles.forEach(role => {
				if (authRoles.includes(role)) ret = true
			})
			return ret
		}
		case 'update': {
			const entity = data
			let type = entity.type==='node'
				?entity.bundle
				:entity.type
			if (!type) return false
			if (!auth.entity[type] || !auth.entity[type].update) return false
			let authRoles = auth.entity[type].update
			
			let ret = false
			user.roles.forEach(role => {
				if (authRoles.includes(role)) ret = true
			})
			return ret
		}
		case 'delete': {
			const entity = data
			let type = entity.type==='node'
				?entity.bundle
				:entity.type
			if (!type) return false
			if (!auth.entity[type] || !auth.entity[type].delete) return false
			let authRoles = auth.entity[type].delete
			
			let ret = false
			user.roles.forEach(role => {
				if (authRoles.includes(role)) ret = true
			})
			return ret
		}
		default:
			return false
	}
}
createPermission('create', {cb: entityAccess})
createPermission('update', {cb: entityAccess})
createPermission('delete', {cb: entityAccess})

const entityCollection = entity => entity.type==='node' 
			? 'node' 
			: ( (entityTypes[entity.type] && entityTypes[entity.type].collection)
				?entityTypes[entity.type].collection
				:entity.type)

export {nodeBundles, entityTypes, entityCollection}
