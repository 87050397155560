import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

class Bg extends React.Component {
	state = {
		i: 0
	}
	componentDidMount() {
		this.interval = setInterval(this.changeImage, 15000)
	}
	componentWillUnmount() {
		this.unmounted = true
		clearInterval(this.interval)
	}
	changeImage = () => {
		if (this.unmounted) return
		let i = this.state.i + 1
		if (i>11) i = 0
		this.setState({i})
	}
	render() {
		const {i} = this.state
		const file = ('0' + i).slice(-2)
		return	<TransitionGroup className='site-bg' appear={true} enter={true}>
					<Transition key={i} unmountOnExit={true}
						timeout={3000}>
						{status => <div className={status} style={{backgroundImage: `url(/backs/bg_${file}.jpg)`}}/>}
					</Transition>
				</TransitionGroup>
		
	}
}

export default Bg
