import React from 'react'
import Query from '../../lib/Query'
import {Link, FaIcon} from '../../ui'
import Loading from '../lib/Loading'

class IndexSymposium extends React.Component {
	state = {
		open: false
	}
	renderNode = (node, language, location) => {
		let title = node.title
		let paths = node.path.filter(item => item.lang===language)
		let path = '/'
		if (paths.length>0) path = paths[0].p
		if (node._i18n[language]) {
			title = node._i18n[language].title
		}
		let classes = ''
		if (path===location) classes = 'active'
		return <Link to={path} className={classes} onActivate={this.close}>{title}</Link>
	}
	toggle = e => {
		this.setState({open: !this.state.open})
	}
	close = e => {
		this.setState({open: false})
		return true
	}
	renderCurrent = (nodes, language, location) => {
		if (!nodes) return null
		for(let i=0; i< nodes.length; i++) {
			const node = nodes[i]
			let title = node.title
			let paths = node.path.filter(item => item.lang===language)
			let path = '/'
			if (paths.length>0) path = paths[0].p
			if (path===location) {
				if (node._i18n[language]) {
					title = node._i18n[language].title
				}
				return (
					<div id='symposium-current' onClick={this.toggle}>
						<span>{title}</span>
						<FaIcon icon='faCaretDown'/>
					</div>
				)
			}
		}
		return null
	}
	render() {
		const {language} = this.props
		const {open} = this.state
		
		const q = {
			query: {bundle: 'symposium',
				$or: [
					{_lang: language},
					{[`_i18n.${language}`]: {$exists: true}}
				]},
			projection: {title: 1, path: 1, _i18n: 1},
			sort: {date: -1}
		}

		const location = typeof window === 'undefined'
			?global.location
			:window.location.pathname
		
		return (
			<Query q={q}>
			{(nodes, status, id, total) => (status!=='fulfilled' || !nodes)
				?<Loading/>
				:<div id='symposium-index' className={open?'open':''}>
				{this.renderCurrent(nodes, language, location)}
				<div id='index-inner'><ul>
					{nodes.map((node, i) => <li key={i}>{this.renderNode(node, language, location)}</li>)}
				</ul></div></div>
			}
			</Query>
		)
	}
}

export default IndexSymposium
