import {query, getEntity, update, queryRefreshAll} from '../lib/api'

const nodeBundles = {
	/*homepage: {
		name: 'Home',
	},*/
	article: {
		name: 'Articol',
		searchField: 'title',
		fields: {
			title: {fullWidth: true},
		},
		afterSave: entity => {
			let promises = []
			let nodesMap = {}

			// select all node ids that reference images from this article
			const id = entity._id.$oid
			promises.push(query({
				query: {'img.meta.articles.id': id},
				projection: {_id:1}
			}))
			
			// get self
			let found = false
			// get all artist nodes
			if (entity.img)
				entity.img.forEach(img => {
					if (!img.meta || !img.meta.artist || !img.meta.artist.id) return
					promises.push(getEntity(img.meta.artist.id))
				})
			// get all article nodes
			if (entity.img)
				entity.img.forEach(img => {
					if (!img.meta || !img.meta.articles) return
					img.meta.articles.forEach(art => {
						if (art.id===entity._id.$oid) found = true
						promises.push(getEntity(art.id))
					})
				})
			if (!found) promises.push(getEntity(entity._id.$oid))
				
			Promise.all(promises)
				.then(results => {
					const allNodes = results[0].results
					
					const nodes = results.slice(1)
					nodes.forEach(n => nodesMap[n._id.$oid] = n)
					
					promises = []
					allNodes.forEach(node => {
						if (!nodesMap[node._id.$oid]) promises.push(getEntity(node._id.$oid))
					})
					Promise.all(promises)
						.then(nodes => {
							nodes.forEach(n => nodesMap[n._id.$oid] = n)
						
							// check self - add/update self reference
							const self = nodesMap[id]
							if (self.img)
							self.img.forEach(img => {
								if (!img.meta) return
								if (!img.meta.articles) img.meta.articles = []
								let found = false
								img.meta.articles.forEach(art => {
									if (art.id === id) {
										found = true
										art.title = self.title
										art._path = self.path
										art._i18n = {}
										if (self._i18n) {
											Object.keys(self._i18n).forEach(lang => {
												art._i18n[lang] = {title: self._i18n[lang].title}
											})
										}
									}
								})
								if (!found) {
									const art = {
										id: id,
										title: self.title,
										_path: self.path,
										_i18n: {}
									}
									if (self._i18n) {
										Object.keys(self._i18n).forEach(lang => {
											art._i18n[lang] = {title: self._i18n[lang].title}
										})
									}
									img.meta.articles.push(art)
								}
							})
					
							// for each image update artist/articles image
							if (self.img)
							self.img.forEach(img => {
								if (!img.meta) return
							
								if (img.meta.artist && img.meta.artist.id && nodesMap[img.meta.artist.id]) {
									const node = nodesMap[img.meta.artist.id]
						
									if (!node.img) node.img = []
									let found = false
									node.img.forEach(imgArtist => {
										if (imgArtist.url !== img.url) return
										found = true
										imgArtist.meta = img.meta
									})
									if (!found) node.img.push(img)
								}
								if (img.meta.articles) {
									img.meta.articles.forEach(art => {
										if (nodesMap[art.id]) {
											const node = nodesMap[art.id]
						
											if (!node.img) node.img = []
											let found = false
											node.img.forEach(imgArt => {
												if (imgArt.url !== img.url) return
												found = true
												imgArt.meta = img.meta
											})
											if (!found) node.img.push(img)
										}
									})
								}
							})
							
							// remove extra
							Object.keys(nodesMap).forEach(id => {
								const node = nodesMap[id]
								if (node.img)
								node.img = node.img.filter(img => {
									found = false
									if (!img.meta || !img.meta.articles) return true
									img.meta.articles.forEach(art => {
										if (art.id===id) found = true
									})
									if (!found) return true
									// img references this article
									found = false
									self.img.forEach(i => {
										if (i.url === img.url) found = true
									})
									return found
								})
							})
					
							// save nodes
							promises = []
							Object.keys(nodesMap).forEach(id => {
								const {_id, _visits, ...$set} = nodesMap[id]
								promises.push(update({
									data: {$set},
									_id
								}))
							})
							Promise.all(promises)
								.then(res => queryRefreshAll())
						})

				})
			

		}
	},
	artist: {
		name: 'Artist',
		searchField: 'title',
		afterSave: entity => {
			let promises = []
			let nodesMap = {}

			// select all node ids that reference images from this artist
			const id = entity._id.$oid
			promises.push(query({
				query: {'img.meta.artist.id': id},
				projection: {_id:1}
			}))
			
			// get self
			let found = false
			// get all artist nodes
			if (entity.img)
			entity.img.forEach(img => {
				if (!img.meta || !img.meta.artist || !img.meta.artist.id) return
				promises.push(getEntity(img.meta.artist.id))
			})
			// get all article nodes
			if (entity.img)
			entity.img.forEach(img => {
				if (!img.meta || !img.meta.articles) return
				img.meta.articles.forEach(art => {
					if (art.id===entity._id.$oid) found = true
					promises.push(getEntity(art.id))
				})
			})
			if (!found) promises.push(getEntity(entity._id.$oid))
				
			Promise.all(promises)
				.then(results => {
					const allNodes = results[0].results
					
					const nodes = results.slice(1)
					nodes.forEach(n => nodesMap[n._id.$oid] = n)
					
					promises = []
					allNodes.forEach(node => {
						if (!nodesMap[node._id.$oid]) promises.push(getEntity(node._id.$oid))
					})
					Promise.all(promises)
						.then(nodes => {
							nodes.forEach(n => nodesMap[n._id.$oid] = n)
						
							// check self - add/update self reference
							const self = nodesMap[id]
							if (self.img)
							self.img.forEach(img => {
								if (!img.meta) return
								img.meta.artist = {
									id: id,
									title: self.title,
									_path: self.path
								}
							})
					
							// for each image update artist/articles image
							if (self.img)
							self.img.forEach(img => {
								if (!img.meta) return
							
								if (img.meta.articles) {
									img.meta.articles.forEach(art => {
										if (nodesMap[art.id]) {
											const node = nodesMap[art.id]
						
											if (!node.img) node.img = []
											let found = false
											node.img.forEach(imgArt => {
												if (imgArt.url !== img.url) return
												found = true
												imgArt.meta = img.meta
											})
											if (!found) node.img.push(img)
										}
									})
								}
							})
							
							// remove extra
							Object.keys(nodesMap).forEach(id => {
								const node = nodesMap[id]
								if (node.img)
								node.img = node.img.filter(img => {
									if (!img.meta || !img.meta.artist) return true
									if (img.meta.artist.id!==id) return true
									// img references this article
									found = false
									self.img.forEach(i => {
										if (i.url === img.url) found = true
									})
									return found
								})
							})
					
							// save nodes
							promises = []
							Object.keys(nodesMap).forEach(id => {
								const {_id, _visits, ...$set} = nodesMap[id]
								promises.push(update({
									data: {$set},
									_id
								}))
							})
							Promise.all(promises)
								.then(res => queryRefreshAll())
						})

				})
			

		}
	},
	symposium: {
		name: 'Simpozion',
		searchField: 'title',
		fields: {
			title: {fullWidth: true},
		},
	}
}

const entityTypes = {
	/*fragment: {
		name: 'Fragment',
		getLabel: entity => entity.title
	}*/
}
export {nodeBundles, entityTypes}
